import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-success-screen',
  templateUrl: './success-screen.component.html',
  styleUrls: ['./success-screen.component.scss']
})
export class SuccessScreenComponent implements OnInit {
  thanksMessage:any;
  teamMessage:any;

  title = 'AI-powered Azure Automation Success';
  constructor(private activatedRoute: ActivatedRoute,private router: Router,private titleService:Title,) { }

  ngOnInit(){
    
    const flag = JSON.stringify(this.activatedRoute.snapshot.queryParams);
    const flagObj = JSON.parse(flag);
    this.thanksMessage =flagObj.thanksMsg;
    this.teamMessage =flagObj.teamMsg;   
  }

}
