import { Component, OnInit } from '@angular/core';
import { Router ,NavigationEnd} from '@angular/router';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-case-studies',
  templateUrl: './case-studies.component.html',
  styleUrls: ['./case-studies.component.scss']
})
export class CaseStudiesComponent implements OnInit {
  title = 'AI-powered Azure Automation Customer Case Studies';
  constructor(private router: Router,private titleService:Title) { }

  ngOnInit(){
    
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  }

  navigateToCaseStudyOne(){
      this.router.navigate(['caseStudies'],{queryParams: {caseStudy:"one"}});
  }
  navigateToCaseStudyTwo(){
    this.router.navigate(['caseStudies'],{queryParams: {caseStudy:"two"}});
  }
}
