import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  title = 'AI-powered Azure Automation Blog';
  constructor(private router: Router,private titleService:Title) { }

  ngOnInit(){
    
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  }

  navigateToBlogDetails(){
    this.router.navigate(['blog-details']);
  }

}
