import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import {Router,NavigationEnd} from "@angular/router";
@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.scss']
})
export class EmailTemplateComponent implements OnInit {
  title = 'AI-powered Azure Automation Email';
  constructor(private router: Router,private titleService:Title) { }

  ngOnInit() {
    
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  }

  navigateToLinkedin(){
    window.location.href = 'https://www.linkedin.com/company/azure-automation-as-service/';
  }
  navigateToTwitter(){
    window.location.href = ' https://twitter.com/ai_a3s';
  }

}
