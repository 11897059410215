<section class="pt-5 hero-section-bg pb-5">
    <div class="pt-3">
        <div class="d-flex justify-content-center text" data-ride="carousel">
            <div class="col-xs-5 col-md-5 col-sm-5 ">
                <div class="">
                    <!-- <img class="w-100 carousel-img" src="assets/images/overview-hero-img.png" alt="hero-image"> -->
                    <div class="text-center justify-content-center">
                        <h1 class="plans-title pt-5 mt-2">Flexible pricing to fit all your needs</h1>
                        <h2 class="plans-subtitle">Choose one of our plans or build your own.</h2>
                        <div class="col-12 d-flex justify-content-center pt-2">
                            <!-- <button class="play-button me-2 justify-content-center d-flex" data-toggle="pricingmodal"
                                data-src="https://www.youtube.com/embed/Jfrjeg26Cwk"
                                data-target="#pricingmodal"><span><img class=" mx-2" src="assets/images/play-button.png"
                                        style="width: 15px;height:15px" /></span>Is it for me?</button> -->
                            <button type="button" class="play-button me-2 justify-content-center d-flex video-btn"
                            (click)="openModal(openPricingVideo)">
                                <span><img class=" mx-2" src="assets/images/play-button.png"
                                        style="width: 15px;height:15px" /></span>Is it for me?
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="container my-3">
    <div class="col-12 d-flex justify-content-center mt-3 pt-5">
        <label class="col-11 pricing-main-title">AI-powered Azure Automation as a Service or A3S is also available on
            <a class="a" style="text-decoration: none">Azure Marketplace</a> and <a class="a"
                style="text-decoration: none">AppSource</a> for your ease of access, trial and
            usage.</label>
    </div>
    <div class="col-12 d-flex justify-content-center my-3">
        <img src="assets/images/microsoft-azure.png" class="microsoft-img px-2 py-2 pointer"
            (click)="redirectToMarketPlace()" />
        <img src="assets/images/microsoft-appsource.png" class="microsoft-img px-2 py-2 pointer"
            (click)="redirectToAppSource()" />
    </div>
</section>
<section class="container text-right ">
    <div class="col-xs-12 col-md-12 col-sm-12 my-5 py-3 justify-content-end " id="plans" style="width:100%">
        <div class="row row-cols-1 row-cols-md-3 g-4 justify-content-end" style="width:100%">
            <div class="col-xs-2 col-md-2 col-sm-2" class="card-width">
                <div class="card card-height" style="background-color: #EFFEFF">
                    <img src="assets/icons/basic-solid-line.png" />
                    <div class="card-body py-3">
                        <label class="plans-card-title">Basic</label>
                        <hr />
                        <div class="content-height">
                            <label class="plans-card-sub-title">$2,500</label>
                            <p class="price-content">per month</p>
                            <label class="plans-price-label mt-3">$2,125</label>
                            <p class="price-content">Annual subscription, paid monthly</p>
                        </div>
                        <button class="plans-card-button mt-4" (click)="navigateToContactUs()">Get started</button>
                    </div>
                </div>
            </div>
            <div class="col-xs-2 col-md-2 col-sm-2" class="card-width">
                <div class="card card-height" style="background-color: #F3FFF1">
                    <img src="assets/icons/standard-solid-line.png" />
                    <div class="card-body py-3">
                        <label class="plans-card-title">Standard</label>
                        <hr />
                        <div class="content-height">
                            <label class="plans-card-sub-title">$5,000</label>
                            <p class="price-content">per month</p>
                            <label class="plans-price-label mt-3">$4,250</label>
                            <p class="price-content">Annual subscription, paid monthly</p>
                        </div>
                        <button class="plans-card-button mt-4" (click)="navigateToContactUs()">Get started</button>
                    </div>
                </div>
            </div>
            <div class="col-xs-2 col-md-2 col-sm-2" class="card-width">
                <div class="card card-height" style="background-color: #FDF2F7">
                    <img src="assets/icons/enterprise-solid-line.png" />
                    <div class="card-body py-3">
                        <label class="plans-card-title">Enterprise</label>
                        <hr />
                        <div class="content-height">
                            <label class="custom-quote">Custom Quote</label>
                            <!-- <p class="plans-card-sub-title" style="color: transparent">$5,000</p>
                                <label class="plans-card-sub-title" style="color: transparent">per month</label>
                                <label class="plans-price-label mt-3" style="color: transparent">$4,250</label>
                                <p class="price-content" style="color: transparent">Annual subscription,paid monthly</p> -->
                        </div>
                        <button class="plans-card-button mt-4" style="margin-bottom:0px;bottom:0px"
                            (click)="navigateToContactUs()">Contact us</button>
                    </div>
                </div>
            </div>
            <div class="col-xs-3 col-md-3 col-sm-3" class="card-width">
                <div class="card card-height" style="background-color: #F4FDFF">
                    <img src="assets/icons/build-solid-line.png" />
                    <div class="card-body py-3">
                        <label class="plans-card-title">Build Your Own</label>
                        <hr />
                        <div class="content-height">
                            <label class="custom-quote">Custom Quote</label>
                            <!-- <p class="plans-card-sub-title" style="color: transparent">$5,000</p>
                                <label class="plans-card-sub-title" style="color: transparent">per month</label>
                                <label class="plans-price-label mt-3" style="color: transparent">$4,250</label>
                                <p class="price-content" style="color: transparent">Annual subscription,paid monthly</p> -->
                        </div>

                        <button class="plans-card-button mt-4" style="margin-bottom:0px;bottom:0px"
                            (click)="navigateToContactUs()">Get a quote</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="container">
    <mat-accordion (opened)="panelOpenDigitalModernization = true" class="my-4 pb-4"
        (closed)="panelOpenDigitalModernization = false">
        <mat-expansion-panel>
            <mat-expansion-panel-header class="disable_ripple">
                <mat-panel-title class="accordion-menu-title">
                   <h2> Digital Modernization </h2>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <label class="accordion-panel-title mt-2">Apps & Infrastructure</label>
            <div class="mt-3">
                <hr class="ms-0 me-3 my-0" />
                <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #FFFFFF;border: none">
                            <div class="card-body  py-3 px-0 text-start">
                                <label class="price-content">Assessment</label>
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #EFFEFF;border: none">
                            <div class="card-body py-3 text-center">
                                <img src="assets/images/dash-icon.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #F3FFF1;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #FDF2F7;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #F4FDFF;border: none">
                            <div class="card-body py-3 pe-0 text-center">
                                <input type="checkbox" class="form-check-input" />
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="ms-0 me-3 my-0" />
                <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #FFFFFF;border: none">
                            <div class="card-body  py-3 px-0 text-start">
                                <label class="price-content">Datacenter Migration</label>
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #EFFEFF;border: none">
                            <div class="card-body py-3 text-center">
                                <img src="assets/images/dash-icon.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #F3FFF1;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #FDF2F7;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #F4FDFF;border: none">
                            <div class="card-body py-3 pe-0 text-center">
                                <input type="checkbox" class="form-check-input" />
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="ms-0 me-3 my-0" />
                <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #FFFFFF;border: none">
                            <div class="card-body  py-3 px-0 text-start">
                                <label class="price-content">Architecture Blueprint</label>
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #EFFEFF;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #F3FFF1;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #FDF2F7;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #F4FDFF;border: none">
                            <div class="card-body py-3 pe-0 text-center">
                                <input type="checkbox" class="form-check-input" />
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="ms-0 me-3 my-0" />
                <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #FFFFFF;border: none">
                            <div class="card-body  py-3 px-0 text-start">
                                <label class="price-content">Business Continuity</label>
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #EFFEFF;border: none">
                            <div class="card-body py-3 text-center">
                                <img src="assets/images/dash-icon.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #F3FFF1;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #FDF2F7;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #F4FDFF;border: none">
                            <div class="card-body py-3 pe-0 text-center">
                                <input type="checkbox" class="form-check-input" />
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="ms-0 me-3 my-0" />
                <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #FFFFFF;border: none">
                            <div class="card-body  py-3 px-0 text-start">
                                <label class="price-content">Management</label>
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #EFFEFF;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #F3FFF1;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #FDF2F7;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #F4FDFF;border: none">
                            <div class="card-body py-3 pe-0 text-center">
                                <input type="checkbox" class="form-check-input" />
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="ms-0 me-3 my-0" />
            </div>


            <label class="accordion-panel-title mt-2">Data & AI</label>
            <div class="mt-3">
                <hr class="ms-0 me-3 my-0" />
                <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #FFFFFF;border: none">
                            <div class="card-body  py-3 px-0 text-start">
                                <label class="price-content">Data Migration</label>
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #EFFEFF;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #F3FFF1;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #FDF2F7;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #F4FDFF;border: none">
                            <div class="card-body py-3 pe-0 text-center">
                                <input type="checkbox" class="form-check-input" />
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="ms-0 me-3 my-0" />
                <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #FFFFFF;border: none">
                            <div class="card-body  py-3 px-0 text-start">
                                <label class="price-content">Data Governance</label>
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #EFFEFF;border: none">
                            <div class="card-body py-3 text-center">
                                <img src="assets/images/dash-icon.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #F3FFF1;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #FDF2F7;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #F4FDFF;border: none">
                            <div class="card-body py-3 pe-0 text-center">
                                <input type="checkbox" class="form-check-input" />
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="ms-0 me-3 my-0" />
                <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #FFFFFF;border: none">
                            <div class="card-body  py-3 px-0 text-start">
                                <label class="price-content">Analytics as a Service</label>
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #EFFEFF;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #F3FFF1;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #FDF2F7;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #F4FDFF;border: none">
                            <div class="card-body py-3 pe-0 text-center">
                                <input type="checkbox" class="form-check-input" />
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="ms-0 me-3 my-0" />
                <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #FFFFFF;border: none">
                            <div class="card-body  py-3 px-0 text-start">
                                <label class="price-content">DataOps</label>
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #EFFEFF;border: none">
                            <div class="card-body py-3 text-center">
                                <img src="assets/images/dash-icon.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #F3FFF1;border: none">
                            <div class="card-body py-3 text-center">
                                <img src="assets/images/dash-icon.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #FDF2F7;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #F4FDFF;border: none">
                            <div class="card-body py-3 pe-0 text-center">
                                <input type="checkbox" class="form-check-input" />
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="ms-0 me-3 my-0" />
            </div>

        </mat-expansion-panel>
    </mat-accordion>
</section>
<section class="container mt-4">
    <mat-accordion (opened)="panelOpenMonitoring = true" class="my-4" (closed)="panelOpenMonitoring = false">
        <mat-expansion-panel>
            <mat-expansion-panel-header class="disable_ripple">
                <mat-panel-title class="accordion-menu-title">
                    <h2>Monitoring & Remediation</h2>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <label class="accordion-panel-title mt-2">Infrastructure Health Monitoring</label>
            <div class="mt-3">
                <hr class="ms-0 me-3 my-0" />
                <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #FFFFFF;border: none">
                            <div class="card-body  py-3 px-0 text-start">
                                <label class="price-content">Overview</label>
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #EFFEFF;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #F3FFF1;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #FDF2F7;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #F4FDFF;border: none">
                            <div class="card-body py-3 pe-0 text-center">
                                <input type="checkbox" class="form-check-input" />
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="ms-0 me-3 my-0" />
                <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #FFFFFF;border: none">
                            <div class="card-body  py-3 px-0 text-start">
                                <label class="price-content">Metrics</label>
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #EFFEFF;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #F3FFF1;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #FDF2F7;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #F4FDFF;border: none">
                            <div class="card-body py-3 pe-0 text-center">
                                <input type="checkbox" class="form-check-input" />
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="ms-0 me-3 my-0" />
                <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #FFFFFF;border: none">
                            <div class="card-body  py-3 px-0 text-start">
                                <label class="price-content">Insights</label>
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #EFFEFF;border: none">
                            <div class="card-body py-3 text-center">
                                <img src="assets/images/dash-icon.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #F3FFF1;border: none">
                            <div class="card-body py-3 text-center">
                                <img src="assets/images/dash-icon.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #FDF2F7;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #F4FDFF;border: none">
                            <div class="card-body py-3 pe-0 text-center">
                                <input type="checkbox" class="form-check-input" />
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="ms-0 me-3 my-0" />
                <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #FFFFFF;border: none">
                            <div class="card-body  py-3 px-0 text-start">
                                <label class="price-content">Alerts</label>
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #EFFEFF;border: none">
                            <div class="card-body py-3 text-center">
                                <img src="assets/images/dash-icon.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #F3FFF1;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #FDF2F7;border: none">
                            <div class="card-body py-3 text-center">
                                <img class="tick" src="assets/images/tick.png" />
                            </div>
                        </div>
                    </div>
                    <div style="width:20%">
                        <div class="card h-100" style="background-color: #F4FDFF;border: none">
                            <div class="card-body py-3 pe-0 text-center">
                                <input type="checkbox" class="form-check-input" />
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="ms-0 me-3 my-0" />
            </div>

            <label class="accordion-panel-title mt-2">Application Health Monitoring</label>
            <div class="mt-3">
                    <hr class="ms-0 me-3 my-0" />
                    <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                <div class="card-body  py-3 px-0 text-start">
                                    <label class="price-content">Overview</label>
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                <div class="card-body py-3 text-center">
                                    <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                <div class="card-body py-3 text-center">
                                    <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                <div class="card-body py-3 text-center">
                                    <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                <div class="card-body py-3 pe-0 text-center">
                                    <input type="checkbox" class="form-check-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="ms-0 me-3 my-0" />
                    <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                <div class="card-body  py-3 px-0 text-start">
                                    <label class="price-content">Metrics</label>
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                <div class="card-body py-3 text-center">
                                    <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                <div class="card-body py-3 text-center">
                                    <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                <div class="card-body py-3 text-center">
                                    <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                <div class="card-body py-3 pe-0 text-center">
                                    <input type="checkbox" class="form-check-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="ms-0 me-3 my-0" />
                    <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                <div class="card-body  py-3 px-0 text-start">
                                    <label class="price-content">Insights</label>
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                <div class="card-body py-3 text-center">
                                    <img src="assets/images/dash-icon.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                <div class="card-body py-3 text-center">
                                    <img src="assets/images/dash-icon.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                <div class="card-body py-3 text-center">
                                    <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                <div class="card-body py-3 pe-0 text-center">
                                    <input type="checkbox" class="form-check-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="ms-0 me-3 my-0" />
                    <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                <div class="card-body  py-3 px-0 text-start">
                                    <label class="price-content">Alerts</label>
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                <div class="card-body py-3 text-center">
                                    <img src="assets/images/dash-icon.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                <div class="card-body py-3 text-center">
                                    <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                <div class="card-body py-3 text-center">
                                    <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                <div class="card-body py-3 pe-0 text-center">
                                    <input type="checkbox" class="form-check-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="ms-0 me-3 my-0" />
                </div>
            <label class="accordion-panel-title mt-2">Cost Monitoring & Optimization</label>
            <div class="mt-3">
                    <hr class="ms-0 me-3 my-0" />
                    <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                <div class="card-body  py-3 px-0 text-start">
                                    <label class="price-content">Overview</label>
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                <div class="card-body py-3 text-center">
                                    <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                <div class="card-body py-3 text-center">
                                    <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                <div class="card-body py-3 text-center">
                                    <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                <div class="card-body py-3 pe-0 text-center">
                                    <input type="checkbox" class="form-check-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="ms-0 me-3 my-0" />
                    <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                <div class="card-body  py-3 px-0 text-start">
                                    <label class="price-content">Cost Analysis</label>
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                <div class="card-body py-3 text-center">
                                    <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                <div class="card-body py-3 text-center">
                                    <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                <div class="card-body py-3 text-center">
                                    <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                <div class="card-body py-3 pe-0 text-center">
                                    <input type="checkbox" class="form-check-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="ms-0 me-3 my-0" />
                    <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                <div class="card-body  py-3 px-0 text-start">
                                    <label class="price-content">Optimize</label>
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                <div class="card-body py-3 text-center">
                                    <img src="assets/images/dash-icon.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                <div class="card-body py-3 text-center">
                                        <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                <div class="card-body py-3 text-center">
                                    <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                <div class="card-body py-3 pe-0 text-center">
                                    <input type="checkbox" class="form-check-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="ms-0 me-3 my-0" />
                    <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                <div class="card-body  py-3 px-0 text-start">
                                    <label class="price-content">Budget & Alerts</label>
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                <div class="card-body py-3 text-center">
                                    <img src="assets/images/dash-icon.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                <div class="card-body py-3 text-center">
                                    <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                <div class="card-body py-3 text-center">
                                    <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                <div class="card-body py-3 pe-0 text-center">
                                    <input type="checkbox" class="form-check-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="ms-0 me-3 my-0" />
                    <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                <div class="card-body  py-3 px-0 text-start">
                                    <label class="price-content">Billing</label>
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                <div class="card-body py-3 text-center">
                                        <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                <div class="card-body py-3 text-center">
                                    <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                <div class="card-body py-3 text-center">
                                    <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                <div class="card-body py-3 pe-0 text-center">
                                    <input type="checkbox" class="form-check-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="ms-0 me-3 my-0" />
                </div>
        </mat-expansion-panel>
    </mat-accordion>
</section>

<section class="container mt-4">
    <mat-accordion (opened)="panelOpenGovernance = true" class="my-4" (closed)="panelOpenGovernance = false">
        <mat-expansion-panel>
            <mat-expansion-panel-header class="disable_ripple">
                <mat-panel-title class="accordion-menu-title">
                   <h2> Governance, Risk and Compliance (GRC)</h2>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <label class="accordion-panel-title mt-2">Governance</label>
            <div class="mt-3">
                
                <div class="mt-3">
                        <hr class="ms-0 me-3 my-0" />
                        <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                            <div style="width:20%">
                                <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                    <div class="card-body  py-3 px-0 text-start">
                                        <label class="price-content">Overview</label>
                                    </div>
                                </div>
                            </div>
                            <div style="width:20%">
                                <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                    <div class="card-body py-3 text-center">
                                        <img  src="assets/images/dash-icon.png" />
                                    </div>
                                </div>
                            </div>
                            <div style="width:20%">
                                <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                    <div class="card-body py-3 text-center">
                                        <img class="tick" src="assets/images/tick.png" />
                                    </div>
                                </div>
                            </div>
                            <div style="width:20%">
                                <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                    <div class="card-body py-3 text-center">
                                        <img class="tick" src="assets/images/tick.png" />
                                    </div>
                                </div>
                            </div>
                            <div style="width:20%">
                                <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                    <div class="card-body py-3 pe-0 text-center">
                                        <input type="checkbox" class="form-check-input" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="ms-0 me-3 my-0" />
                        <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                            <div style="width:20%">
                                <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                    <div class="card-body  py-3 px-0 text-start">
                                        <label class="price-content">Enterprise Policies</label>
                                    </div>
                                </div>
                            </div>
                            <div style="width:20%">
                                <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                    <div class="card-body py-3 text-center">
                                        <img  src="assets/images/dash-icon.png" />
                                    </div>
                                </div>
                            </div>
                            <div style="width:20%">
                                <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                    <div class="card-body py-3 text-center">
                                            <img  src="assets/images/dash-icon.png" />
                                    </div>
                                </div>
                            </div>
                            <div style="width:20%">
                                <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                    <div class="card-body py-3 text-center">
                                        <img class="tick" src="assets/images/tick.png" />
                                    </div>
                                </div>
                            </div>
                            <div style="width:20%">
                                <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                    <div class="card-body py-3 pe-0 text-center">
                                        <input type="checkbox" class="form-check-input" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="ms-0 me-3 my-0" />
                        <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                            <div style="width:20%">
                                <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                    <div class="card-body  py-3 px-0 text-start">
                                        <label class="price-content">Access Management</label>
                                    </div>
                                </div>
                            </div>
                            <div style="width:20%">
                                <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                    <div class="card-body py-3 text-center">
                                        <img src="assets/images/dash-icon.png" />
                                    </div>
                                </div>
                            </div>
                            <div style="width:20%">
                                <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                    <div class="card-body py-3 text-center">
                                            <img class="tick" src="assets/images/tick.png" />
                                    </div>
                                </div>
                            </div>
                            <div style="width:20%">
                                <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                    <div class="card-body py-3 text-center">
                                        <img class="tick" src="assets/images/tick.png" />
                                    </div>
                                </div>
                            </div>
                            <div style="width:20%">
                                <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                    <div class="card-body py-3 pe-0 text-center">
                                        <input type="checkbox" class="form-check-input" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="ms-0 me-3 my-0" />
                        <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                            <div style="width:20%">
                                <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                    <div class="card-body  py-3 px-0 text-start">
                                        <label class="price-content">Logging</label>
                                    </div>
                                </div>
                            </div>
                            <div style="width:20%">
                                <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                    <div class="card-body py-3 text-center">
                                        <img src="assets/images/dash-icon.png" />
                                    </div>
                                </div>
                            </div>
                            <div style="width:20%">
                                <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                    <div class="card-body py-3 text-center">
                                        <img class="tick" src="assets/images/tick.png" />
                                    </div>
                                </div>
                            </div>
                            <div style="width:20%">
                                <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                    <div class="card-body py-3 text-center">
                                        <img class="tick" src="assets/images/tick.png" />
                                    </div>
                                </div>
                            </div>
                            <div style="width:20%">
                                <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                    <div class="card-body py-3 pe-0 text-center">
                                        <input type="checkbox" class="form-check-input" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="ms-0 me-3 my-0" />
                        <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                            <div style="width:20%">
                                <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                    <div class="card-body  py-3 px-0 text-start">
                                        <label class="price-content">Auditing</label>
                                    </div>
                                </div>
                            </div>
                            <div style="width:20%">
                                <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                    <div class="card-body py-3 text-center">
                                            <img class="tick" src="assets/images/tick.png" />
                                    </div>
                                </div>
                            </div>
                            <div style="width:20%">
                                <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                    <div class="card-body py-3 text-center">
                                        <img class="tick" src="assets/images/tick.png" />
                                    </div>
                                </div>
                            </div>
                            <div style="width:20%">
                                <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                    <div class="card-body py-3 text-center">
                                        <img class="tick" src="assets/images/tick.png" />
                                    </div>
                                </div>
                            </div>
                            <div style="width:20%">
                                <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                    <div class="card-body py-3 pe-0 text-center">
                                        <input type="checkbox" class="form-check-input" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="ms-0 me-3 my-0"/>
                    </div>
            </div>

            <label class="accordion-panel-title mt-2">Security</label>
            <div class="mt-3">
                    
                    <div class="mt-3">
                            <hr class="ms-0 me-3 my-0" />
                            <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                        <div class="card-body  py-3 px-0 text-start">
                                            <label class="price-content">Overview</label>
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                        <div class="card-body py-3 text-center">
                                            <img  src="assets/images/dash-icon.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                        <div class="card-body py-3 text-center">
                                            <img class="tick" src="assets/images/tick.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                        <div class="card-body py-3 text-center">
                                            <img class="tick" src="assets/images/tick.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                        <div class="card-body py-3 pe-0 text-center">
                                            <input type="checkbox" class="form-check-input" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="ms-0 me-3 my-0" />
                            <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                        <div class="card-body  py-3 px-0 text-start">
                                            <label class="price-content">Identity Security</label>
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                        <div class="card-body py-3 text-center">
                                            <img  src="assets/images/dash-icon.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                        <div class="card-body py-3 text-center">
                                                <img  src="assets/images/dash-icon.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                        <div class="card-body py-3 text-center">
                                            <img class="tick" src="assets/images/tick.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                        <div class="card-body py-3 pe-0 text-center">
                                            <input type="checkbox" class="form-check-input" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="ms-0 me-3 my-0" />
                            <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                        <div class="card-body  py-3 px-0 text-start">
                                            <label class="price-content">Endpoint Protection</label>
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                        <div class="card-body py-3 text-center">
                                            <img src="assets/images/dash-icon.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                        <div class="card-body py-3 text-center">
                                                <img src="assets/images/dash-icon.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                        <div class="card-body py-3 text-center">
                                            <img class="tick" src="assets/images/tick.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                        <div class="card-body py-3 pe-0 text-center">
                                            <input type="checkbox" class="form-check-input" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="ms-0 me-3 my-0" />
                            <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                        <div class="card-body  py-3 px-0 text-start">
                                            <label class="price-content">Malicious Activities</label>
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                        <div class="card-body py-3 text-center">
                                            <img src="assets/images/dash-icon.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                        <div class="card-body py-3 text-center">
                                                <img src="assets/images/dash-icon.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                        <div class="card-body py-3 text-center">
                                            <img class="tick" src="assets/images/tick.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                        <div class="card-body py-3 pe-0 text-center">
                                            <input type="checkbox" class="form-check-input" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="ms-0 me-3 my-0" />
                            <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                        <div class="card-body  py-3 px-0 text-start">
                                            <label class="price-content">Network Security</label>
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                        <div class="card-body py-3 text-center">
                                                <img src="assets/images/dash-icon.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                        <div class="card-body py-3 text-center">
                                                <img src="assets/images/dash-icon.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                        <div class="card-body py-3 text-center">
                                            <img class="tick" src="assets/images/tick.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                        <div class="card-body py-3 pe-0 text-center">
                                            <input type="checkbox" class="form-check-input" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="ms-0 me-3 my-0" />
                            <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                                    <div style="width:20%">
                                        <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                            <div class="card-body  py-3 px-0 text-start">
                                                <label class="price-content">Application Security</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="width:20%">
                                        <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                            <div class="card-body py-3 text-center">
                                                    <img src="assets/images/dash-icon.png" />
                                            </div>
                                        </div>
                                    </div>
                                    <div style="width:20%">
                                        <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                            <div class="card-body py-3 text-center">
                                                    <img src="assets/images/dash-icon.png" />
                                            </div>
                                        </div>
                                    </div>
                                    <div style="width:20%">
                                        <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                            <div class="card-body py-3 text-center">
                                                <img class="tick" src="assets/images/tick.png" />
                                            </div>
                                        </div>
                                    </div>
                                    <div style="width:20%">
                                        <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                            <div class="card-body py-3 pe-0 text-center">
                                                <input type="checkbox" class="form-check-input" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <hr class="ms-0 me-3 my-0" />
                            <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                                    <div style="width:20%">
                                        <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                            <div class="card-body  py-3 px-0 text-start">
                                                <label class="price-content">Alerts</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="width:20%">
                                        <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                            <div class="card-body py-3 text-center">
                                                    <img src="assets/images/dash-icon.png" />
                                            </div>
                                        </div>
                                    </div>
                                    <div style="width:20%">
                                        <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                            <div class="card-body py-3 text-center">
                                                    <img class="tick" src="assets/images/tick.png" />
                                            </div>
                                        </div>
                                    </div>
                                    <div style="width:20%">
                                        <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                            <div class="card-body py-3 text-center">
                                                <img class="tick" src="assets/images/tick.png" />
                                            </div>
                                        </div>
                                    </div>
                                    <div style="width:20%">
                                        <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                            <div class="card-body py-3 pe-0 text-center">
                                                <input type="checkbox" class="form-check-input" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr class="ms-0 me-3 my-0" />
                        </div>
                </div>

            <label class="accordion-panel-title mt-2">Compliance</label>
                  
                    <div class="mt-3">
                            <hr class="ms-0 me-3 my-0" />
                            <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                        <div class="card-body  py-3 px-0 text-start">
                                            <label class="price-content">Overview</label>
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                        <div class="card-body py-3 text-center">
                                            <img  src="assets/images/dash-icon.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                        <div class="card-body py-3 text-center">
                                            <img class="tick" src="assets/images/tick.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                        <div class="card-body py-3 text-center">
                                            <img class="tick" src="assets/images/tick.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                        <div class="card-body py-3 pe-0 text-center">
                                            <input type="checkbox" class="form-check-input" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="ms-0 me-3 my-0" />
                            <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                        <div class="card-body  py-3 px-0 text-start">
                                            <label class="price-content">Compliance Benchmark</label>
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                        <div class="card-body py-3 text-center">
                                            <img  src="assets/images/dash-icon.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                        <div class="card-body py-3 text-center">
                                                <img  src="assets/images/dash-icon.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                        <div class="card-body py-3 text-center">
                                            <img class="tick" src="assets/images/tick.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                        <div class="card-body py-3 pe-0 text-center">
                                            <input type="checkbox" class="form-check-input" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="ms-0 me-3 my-0"/>
                            <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                        <div class="card-body  py-3 px-0 text-start">
                                            <label class="price-content">Compliance Status</label>
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                        <div class="card-body py-3 text-center">
                                            <img src="assets/images/dash-icon.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                        <div class="card-body py-3 text-center">
                                                <img src="assets/images/dash-icon.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                        <div class="card-body py-3 text-center">
                                            <img class="tick" src="assets/images/tick.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                        <div class="card-body py-3 pe-0 text-center">
                                            <input type="checkbox" class="form-check-input" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="ms-0 me-3 my-0" />
                            <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                        <div class="card-body  py-3 px-0 text-start">
                                            <label class="price-content">Policy Management</label>
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                        <div class="card-body py-3 text-center">
                                            <img src="assets/images/dash-icon.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                        <div class="card-body py-3 text-center">
                                                <img src="assets/images/dash-icon.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                        <div class="card-body py-3 text-center">
                                            <img class="tick" src="assets/images/tick.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                        <div class="card-body py-3 pe-0 text-center">
                                            <input type="checkbox" class="form-check-input" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="ms-0 me-3 my-0" />
                            <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                        <div class="card-body  py-3 px-0 text-start">
                                            <label class="price-content">Reports</label>
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                        <div class="card-body py-3 text-center">
                                                <img src="assets/images/dash-icon.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                        <div class="card-body py-3 text-center">
                                                <img class="tick" src="assets/images/tick.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                        <div class="card-body py-3 text-center">
                                            <img class="tick" src="assets/images/tick.png" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                        <div class="card-body py-3 pe-0 text-center">
                                            <input type="checkbox" class="form-check-input" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="ms-0 me-3 my-0" />
                        </div>
                
        </mat-expansion-panel>
    </mat-accordion>
</section>

<section class="container mt-4">
    <mat-accordion (opened)="panelOpenDedicatedSupport = true" class="my-4"
        (closed)="panelOpenDedicatedSupport = false">
        <mat-expansion-panel>
            <mat-expansion-panel-header class="disable_ripple">
                <mat-panel-title class="accordion-menu-title">
                    <h2>Dedicated Support</h2>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <label class="accordion-panel-title mt-2">Relentless & Reliable 24x& Support</label>
            <div class="mt-3">
                    <hr class="ms-0 me-3 my-0" />
                    <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                <div class="card-body  py-3 px-0 text-start">
                                    <label class="price-content">A3S Support</label>
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                <div class="card-body py-3 text-center">
                                        <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                <div class="card-body py-3 text-center">
                                    <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                <div class="card-body py-3 text-center">
                                    <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                <div class="card-body py-3 pe-0 text-center">
                                    <input type="checkbox" class="form-check-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="ms-0 me-3 my-0" />
                    <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                <div class="card-body  py-3 px-0 text-start">
                                    <label class="price-content">Azure Support</label>
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                <div class="card-body py-3 text-center">
                                    <img  src="assets/images/dash-icon.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                <div class="card-body py-3 text-center">
                                        <img  src="assets/images/dash-icon.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                <div class="card-body py-3 text-center">
                                    <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                <div class="card-body py-3 pe-0 text-center">
                                    <input type="checkbox" class="form-check-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="ms-0 me-3 my-0" />
                    <div class="row row-cols-1 row-cols-md-3 justify-content-end" style="width:100%">
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #FFFFFF;border: none">
                                <div class="card-body  py-3 px-0 text-start">
                                    <label class="price-content">SAP Support</label>
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #EFFEFF;border: none">
                                <div class="card-body py-3 text-center">
                                    <img src="assets/images/dash-icon.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #F3FFF1;border: none">
                                <div class="card-body py-3 text-center">
                                        <img src="assets/images/dash-icon.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #FDF2F7;border: none">
                                <div class="card-body py-3 text-center">
                                    <img class="tick" src="assets/images/tick.png" />
                                </div>
                            </div>
                        </div>
                        <div style="width:20%">
                            <div class="card h-100" style="background-color: #F4FDFF;border: none">
                                <div class="card-body py-3 pe-0 text-center">
                                    <input type="checkbox" class="form-check-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="ms-0 me-3 my-0" />
                </div>
        </mat-expansion-panel>
    </mat-accordion>
</section>
<section class="container">
    <div class="col-xs-12 col-md-12 col-sm-12 my-5 py-5 justify-content-end " id="plans" style="width:100%">
        <div class="row row-cols-1 row-cols-md-3 g-4 justify-content-end" style="width:100%">
            <div class="col-xs-2 col-md-2 col-sm-2" class="card-width">
                <div class=" card" style="background-color: #EFFEFF">
                    <div class="card-body py-3 text-center">
                        <label class="plans-card-title text-center">Basic</label>
                        <hr />
                        <div class="bottom-basic-content-height">

                        </div>
                        <button class="plans-card-button mt-3 mb-2" (click)="navigateToContactUs()">Get started</button>
                    </div>
                    <img src="assets/icons/basic-solid-line.png" />
                </div>
            </div>
            <div class="col-xs-2 col-md-2 col-sm-2" class="card-width">
                <div class="card " style="background-color: #F3FFF1">
                    <div class="card-body py-3 text-center">
                        <label class="plans-card-title text-center">Standard</label>
                        <hr />
                        <div class="bottom-basic-content-height">

                        </div>
                        <button class="plans-card-button mt-3 mb-2" (click)="navigateToContactUs()">Get started</button>
                    </div>
                    <img src="assets/icons/standard-solid-line.png" />
                </div>
            </div>
            <div class="col-xs-2 col-md-2 col-sm-2" class="card-width">
                <div class="card " style="background-color: #FDF2F7">
                    <div class="card-body py-3 text-center">
                        <label class="plans-card-title text-center">Enterprise</label>
                        <hr />
                        <div class="bottom-basic-content-height">

                        </div>
                        <button class="plans-card-button mt-3 mb-2" style="margin-bottom:0px;bottom:0px"
                            (click)="navigateToContactUs()">Contact us</button>
                    </div>
                    <img src="assets/icons/enterprise-solid-line.png" />
                </div>
            </div>
            <div class="col-xs-3 col-md-3 col-sm-3" class="card-width">
                <div class="card " style="background-color: #F4FDFF">
                    <div class="card-body py-3 text-center">
                        <label class="plans-card-title text-center">Build Your Own</label>
                        <hr />
                        <div class="bottom-basic-content-height">

                        </div>
                        <button class="plans-card-button mt-3 mb-2" style="margin-bottom:0px;bottom:0px"
                            (click)="navigateToContactUs()">Get a quote</button>
                    </div>
                    <img src="assets/icons/build-solid-line.png" />
                </div>
            </div>
        </div>
    </div>
</section>
<!-- <div class="modal fade" id="pricingmodal" tabindex="-1" role="dialog" aria-labelledby="pricingmodalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
              <button type="button" click="hideModal()" class="close" data-dismiss="Modal" aria-label="Close" style="width:31px;
              height: 17px;
              background: transparent;
              border: none;
              justify-content: flex-end;
              float: right;
              align-content: end;
              align-items: flex-end;
              align-self: flex-end;
              text-align: end;">
                  <span aria-hidden="true">&times;</span>
                </button>
            <div class="modal-body">
                <div class="ratio ratio-16x9">
                                    <video width="245" height="190" controls><source src="assets/video/pricing.mp4" type="video/mp4"></video>

                  </div>
            </div>
          </div>
        </div>
      </div> -->
<!-- <div class="modal fade" id="myModal" data-backdrop="static" 
data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" (click)="stop()" class="close" data-dismiss="modal" aria-label="Close" style="width:31px;
                 height: 17px;
                 background: transparent;
                 border: none;
                 justify-content: flex-end;
                 float: right;
                 align-content: end;
                 align-items: flex-end;
                 align-self: flex-end;
                 text-align: end;">
                    <span aria-hidden="true">&times;</span>
                  </button>        
                  16:9 aspect ratio 
          <div class="">
                <video width="100%" height="100%" controls #pricing><source src="https://dynaptresource.blob.core.windows.net/videos/pricing.mp4" type="video/mp4" autoplay="false"></video>
          </div>
                  
                  
                </div>


            </div>

        </div>
    </div> -->


    <ng-template #openPricingVideo>
            <div class="medium-popup-wrapper" >
                <div class="modal-top">
                    <button type="button" (click)="closeModal()" style="border: 1px solid white;background: #FFF;margin-left: 97%;" class="close" aria-label="Close" >
                        <span aria-hidden="true" style="width:15px;height: 15px;">&times;</span>
                    </button>
                </div>
                <div class="modal-body" style="width:100%;">
                        <div class="">
                                <video width="100%" height="100%" controls #pricing autoplay><source src="https://dynaptresource.blob.core.windows.net/videos/pricing.mp4" type="video/mp4" ></video>
                          </div>
                </div>
            </div>
        </ng-template>

<app-get-started></app-get-started>