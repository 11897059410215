<div class="col-12 container pt-5 mt-5">
<div class="col-sm-3 col-xs-3 col-md-3 py-4 justify-content-end text-end float-end">
   <!-- <input type="text" placeholder="search" class="form-control" style="margin-left: -15px;margin-top:-23px;padding-left: 39px;"/>
   <img src="assets/images/search-icon.png" alt="search" class="search-icon" /> -->
  
   <div class="form-group">
        <div class="input-group">
                <input type="text" placeholder="search" class="form-control" style="margin-left: -15px;margin-top:-23px;"/>
                <span class="input-group-addon">
                        <img src="assets/images/search-icon.png" alt="search" class="search-icon" />
                  </span>
          
        </div>
      </div>

</div>

<div class="col-12 d-flex">
    <div class="col-md-6">
      <img src="assets/images/blog-main-image.png" alt="hero-img" class="hero-img" />
    </div>
    <div class="col-md-6 hero-section-bg ms-3">
      <div class="p-5"> 
          <a class="hero-img-title pointer" (click)="navigateToBlogDetails()">Cloud Lifecycle Management: An Overview.</a>
        <p class="hero-img-content pt-3">Cloud Lifecycle Management encompasses assessment, POC/Pilots, to set up of production deployments with best practices, to ongoing monitoring, management and support and thereon continues with new initiatives. Lets dig a bit deeper with this blog post to understand all aspects of Cloud Lifecycle Management and why does it matter.</p>
        <p class="hero-img-tags pt-1">By A3S team  |  2 minute read  |  1 Nov 2021</p>
        <div class="col-9 pt-1">
        <!-- <button class="hero-img-button me-2 my-1">Cloud Strategy</button>
        <button class="hero-img-button  me-2 my-1">Internet of Things</button>
        <button class="hero-img-button me-2 my-1">AI</button>
        <button class="hero-img-button me-2 my-1">Business</button>
        <button class="hero-img-button me-2 my-1">Azure</button> -->
        </div>
      </div>
    </div>
</div>
</div>
<section class="container">
        <div class="col-xs-12 col-md-12 col-sm-12 my-2 py-3" >
            <div class="row row-cols-1 row-cols-md-3 g-4">
                <!-- <div class="col-xs-4 col-md-4 col-sm-4">
                    <div class="card h-100">
                        <img src="assets/images/blog-main-image.png" class="card-img-top blog-img"
                            alt="blog-img">
                        <div class="card-body py-3 px-0">
                            <a class="blog-card-title pointer" (click)="navigateToBlogDetails()">Cloud Lifecycle Management: An Overview.</a>
                            <p class="blog-card-content pt-2">Cloud Lifecycle Management encompasses assessment, POC/Pilots, to set up of production deployments with best practices, to ongoing monitoring, management and support and thereon continues with new initiatives. Lets dig a bit deeper with this blog post to understand all aspects of Cloud Lifecycle Management and why does it matter.</p> -->
                            <!-- <p class="hero-img-tags pt-1">By Saurabh Sawalia  |  2 minute read  |  22 Sep 2021</p>     -->
                            <!-- <button class="blog-card-btn me-2 my-1">Cloud Strategy</button>
                                <button class="blog-card-btn me-2 my-1">Internet of Things</button>
                                <button class="blog-card-btn me-2 my-1">AI</button>
                                <button class="blog-card-btn me-2 my-1">Business</button>
                                <button class="blog-card-btn me-2 my-1">Azure</button> -->
                        <!-- </div>
                    </div>
                </div> -->
                <div class="col-xs-4 col-md-4 col-sm-4">
                        <div class="card blog-img" style="background: #48586B;">
                          <!-- <img src="assets/images/cloudways.png" class="card-img-top case-studies-card" alt="teams-ims"> -->
                          <div class=" px-3 py-4">
                            <!-- <h5 class="card-title">Cloudways</h5> -->
                            <p class="case-card-title pointer px-3" style="color:#FFF;vertical-align: middle">Coming soon... <br /><br /><br /></p>
                
                            <a class="case-study-label" style="color: transparent;">Full case study </a>
                          </div>
                        </div>
                      </div>
                <!-- <div class="col-xs-4 col-md-4 col-sm-4">
                    <div class="card h-100">
                        <div class="d-flex">
                            <img src="assets/images/blog-main-image.png" class="card-img-top blog-img" alt="blog-img">
                        </div>
                        <div class="card-body py-3 px-0">
                            <a class="blog-card-title pointer" (click)="navigateToBlogDetails()">Cloud Lifecycle Management: An Overview.</a>
                            <p class="blog-card-content pt-2">As an organization when you decide to adopt a Public Cloud platform, you are truly embarking...</p>
                             <p class="hero-img-tags pt-1">By Saurabh Sawalia  |  2 minute read  |  22 Sep 2021</p>     -->
                            <!-- <button class="blog-card-btn me-2 my-1">Cloud Strategy</button>
                                <button class="blog-card-btn me-2 my-1">Internet of Things</button>
                                <button class="blog-card-btn me-2 my-1">AI</button>
                                <button class="blog-card-btn me-2 my-1">Business</button>
                                <button class="blog-card-btn me-2 my-1">Azure</button> 
                        </div>
                    </div>
                </div> -->
                <!-- <div class="col-xs-4 col-md-4 col-sm-4">
                    <div class="card h-100">
                        <img src="assets/images/blog-main-image.png" class="card-img-top blog-img" alt="blog-img">
                        <div class="card-body py-3 px-0">
                            <a class="blog-card-title pointer" (click)="navigateToBlogDetails()">Cloud Lifecycle Management: An Overview.</a>
                            <p class="blog-card-content pt-2">As an organization when you decide to adopt a Public Cloud platform, you are truly embarking...</p>
                             <p class="hero-img-tags pt-1">By Saurabh Sawalia  |  2 minute read  |  22 Sep 2021</p>     -->
                            <!-- <button class="blog-card-btn me-2 my-1">Cloud Strategy</button>
                                <button class="blog-card-btn me-2 my-1">Internet of Things</button>
                                <button class="blog-card-btn me-2 my-1">AI</button>
                                <button class="blog-card-btn me-2 my-1">Business</button>
                                <button class="blog-card-btn me-2 my-1">Azure</button> 
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </section>
    <app-get-started></app-get-started>
    