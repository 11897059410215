<section class="container">
<div class="container col-12 py-5 my-5">
    <div class="col-xs-12 col-md-12 col-sm-12 pt-2">
        <form class="col-xs-12 col-md-12 col-sm-12 pt-5 row g-1" [formGroup]="contactUsForm"  #myform="ngForm" >
            <div class="col-xs-6 col-md-6 col-sm-6 pe-5" *ngIf="this.contactUsImage == true">
                <img src="assets/images/contact-us.png" class="contact-us-img" alt="get-in-touch-img" />
            </div>
            <div class="col-xs-6 col-md-6 col-sm-6 pe-5" *ngIf="this.tryNowImage == true">
                    <img src="assets/images/try-now-img.png" class="contact-us-img" alt="get-in-touch-img" />
                </div>
            <div class="col-xs-6 col-md-6 col-sm-6 mt-1 form-box-shadow pe-3">
                <div class="p-5 ">
                    <div class="col-xs-11 col-md-11 col-sm-11">
                        <label class="contact-us-title">Submit the form below to get in touch with our team.</label>
                    </div>
                    <div class="col-xs-12 col-md-12 col-sm-12 mt-4">
                        <div class="d-flex">
                            <label class="contact-us-label">Full name</label>
                            <img src="assets/images/required-icon.png" class="required-icon"/>
                        </div>
                        <input class="form-control text-label" formControlName="fullName" [(ngModel)]="fullName"
                            [ngClass]="(contactUsForm.get('fullName').hasError('required') && myform.submitted)?'form-control-invalid': 'form-control' "
                             type="textbox" placeholder="" required />
                        <div 
                        *ngIf="contactUsForm.get('fullName').hasError('required')&& myform.submitted"
                            class="small text-danger mt-1">Required</div>
                        <div *ngIf="contactUsForm.get('fullName').hasError('pattern')" class="small text-danger mt-1">
                            Full name should contain only alphabets</div>
                        <div *ngIf="contactUsForm.get('fullName').hasError('maxlength')" class="small text-danger mt-1">
                            Full name cannot exceed 200 characters</div>
                        <div *ngIf="contactUsForm.get('fullName').hasError('minlength')" class="small text-danger mt-1">
                            Full name should contain 2 characters</div>
                    </div>

                    <div class="col-xs-12 col-md-12 col-sm-12 mt-5">
                        <div class="d-flex">
                            <label class="contact-us-label">Email</label>
                            <img src="assets/images/required-icon.png" class="required-icon"/>
                        </div>
                        <input class="form-control text-label"  formControlName="email" [(ngModel)]="email"
                            [ngClass]="(contactUsForm.get('email').hasError('required') && myform.submitted)?'form-control-invalid': 'form-control' "
                            type="textbox" placeholder="" required />
                        <div *ngIf="contactUsForm.get('email').hasError('required') && myform.submitted"
                            class="small text-danger mt-1">Required</div>
                        <div *ngIf="contactUsForm.get('email').hasError('pattern')" class="small text-danger mt-1">
                            Invalid email format.</div>
                        <div *ngIf="contactUsForm.get('email').hasError('maxlength')" class="small text-danger mt-1">
                            Eamil cannot exceed 200 characters</div>
                        <div *ngIf="contactUsForm.get('email').hasError('minlength')" class="small text-danger mt-1">
                            Eamil should contain 2 characters</div>
                    </div>

                    <div class="col-xs-12 col-md-12 col-sm-12 mt-5">
                        <div class="d-flex">
                            <label class="contact-us-label">Phone number</label>
                            <img src="assets/images/required-icon.png" class="required-icon"/>
                        </div>
                        <input class="form-control text-label"  formControlName="phoneNumber" [(ngModel)]="phoneNumber"
                            [ngClass]="(contactUsForm.get('phoneNumber').hasError('required') && myform.submitted)?'form-control-invalid': 'form-control' "
                            type="textbox" placeholder="" required />
                        <div *ngIf="contactUsForm.get('phoneNumber').hasError('required') && myform.submitted"
                            class="small text-danger mt-1">Required</div>
                        <div *ngIf="contactUsForm.get('phoneNumber').hasError('pattern')"
                            class="small text-danger mt-1">Phone number allowed digits only</div>
                        <div *ngIf="contactUsForm.get('phoneNumber').hasError('maxlength')"
                            class="small text-danger mt-1">Phone number cannot exceed 30 characters.</div>
                        <div *ngIf="contactUsForm.get('phoneNumber').hasError('minlength')"
                            class="small text-danger mt-1">Phone number should contain 2 characters</div>
                    </div>

                    <div class="col-xs-12 col-md-12 col-sm-12 mt-5">
                        <div class="d-flex">
                            <label class="contact-us-label">Company name</label>
                            <img src="assets/images/required-icon.png" class="required-icon"/>
                        </div>
                        <input class="form-control text-label"  formControlName="companyName" [(ngModel)]="companyName"
                            [ngClass]="(contactUsForm.get('companyName').hasError('required') && myform.submitted)?'form-control-invalid': 'form-control' "
                            type="textbox" placeholder="" required />
                        <div *ngIf="contactUsForm.get('companyName').hasError('required') && myform.submitted"
                            class="small text-danger mt-1">Required</div>
                        <div *ngIf="contactUsForm.get('companyName').hasError('pattern')"
                            class="small text-danger mt-1">Company name allowed alphabets only</div>
                        <div *ngIf="contactUsForm.get('companyName').hasError('maxlength')"
                            class="small text-danger mt-1">Company name cannot exceed 200 characters.</div>
                        <div *ngIf="contactUsForm.get('companyName').hasError('minlength')"
                            class="small text-danger mt-1">Company name should contain 2 characters</div>
                    </div>

                    <div class="col-xs-12 col-md-12 col-sm-12 mt-5">
                        <div class="d-flex">
                            <label class="contact-us-label">Company website URL</label>
                            <img src="assets/images/required-icon.png" class="required-icon"/>
                        </div>
                        <input class="form-control text-label"  formControlName="companyUrl" [(ngModel)]="companyUrl"
                            [ngClass]="(contactUsForm.get('companyUrl').hasError('required') && myform.submitted)?'form-control-invalid': 'form-control' "
                            type="textbox" placeholder="" required />
                        <div *ngIf="contactUsForm.get('companyUrl').hasError('required') && myform.submitted"
                            class="small text-danger mt-1">Required</div>
                            <div *ngIf="contactUsForm.get('companyUrl').hasError('pattern')"
                            class="small text-danger mt-1">Company url should contain https</div>
                        <div *ngIf="contactUsForm.get('companyUrl').hasError('maxlength')"
                            class="small text-danger mt-1">URL cannot exceed 200 characters.</div>
                        <div *ngIf="contactUsForm.get('companyUrl').hasError('minlength')"
                            class="small text-danger mt-1">URL should contain 2 characters</div>
                    </div>

                    <div class="col-xs-12 col-md-12 col-sm-12 mt-5">
                        <div class="d-flex pb-1">
                            <label class="contact-us-label">Message</label>
                            <img src="assets/images/required-icon.png" class="required-icon"/>
                        </div>
                        <textarea class="col-xs-12 col-md-12 col-sm-12 textarea text-label"  formControlName="message" [(ngModel)]="message"
                            [ngClass]="(contactUsForm.get('message').hasError('required') && myform.submitted)?'form-control-invalid': 'form-control' "
                            type="textbox" placeholder="Type your message here..." required ></textarea>
                        <div *ngIf="contactUsForm.get('message').hasError('required') && myform.submitted"
                            class="small text-danger mt-1">Required</div>
                        <div *ngIf="contactUsForm.get('message').hasError('maxlength')" class="small text-danger mt-1">
                            The message cannot exceed 5000 characters.</div>
                        <div *ngIf="contactUsForm.get('message').hasError('minlength')" class="small text-danger mt-1">
                            The message should contain 2 characters</div>
                    </div>

                    <div class="col-xs-12 col-md-12 col-sm-12 mt-5">
                        <button class="col-xs-12 col-md-12 col-sm-12 submit-button pointer" [disabled]="!myform.valid"  type="button" type="submit" (click)="createContact(contactUsForm.value)">Submit</button>
                        <div hidden class="small text-danger mt-1">Something went wrong, please try again later</div>
                    </div>

                </div>
            </div>
        </form>
    </div>
</div>
</section>
<hr class="mt-3">
