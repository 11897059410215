<div class="col-xs-12 col-md-12 col-sm-12 pb-2 container">
    <div class="container" class="footer-position footer-bg mb-4" >
        <div class="col-xs-12 col-md-12 col-sm-12   mt-3 pt-3 d-flex">
            
                    <div class="col-xs-4 col-md-4 col-sm-4 float-left mt-5 mb-2">
                            <img src="assets/icons/logo-icon.png" class="header-logo pointer" (click)="navigateToHomePage()"/>
                            <div class="d-flex">
                                <img src="assets/icons/logo-label.svg" class="header-logo-label pointer pt-1 mt-1" (click)="navigateToHomePage()"/>
                            </div>
                            <div class="d-flex my-4">
                                <a href="https://twitter.com/ai_a3s" target="_blank">
                                    <img src="assets/icons/Twitter.png" class="social-icon pointer pt-1 mt-1" />
                                </a>
                                <a href="https://www.linkedin.com/company/azure-automation-as-service/" target="_blank">
                                    <img src="assets/icons/Linkedin.png" class="social-icon pointer mx-2 pt-1 mt-1"  />
                                </a>
                            </div>
                            <div class="">
                                <div>
                                    <label class="footer-label">2880 Zanker Road,</label>
                                </div>
                                <div>
                                    <label class="footer-label">San Jose,</label>
                                </div>
                                <div>
                                    <label class="footer-label">USA 95134</label>
                                </div>
                                <div class="">
                                    <label class="footer-label py-2" >©2022 Azureautomation.ai</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-3 col-md-3 col-sm-3 mt-5 mb-2">
                            <div>
                                <label class="footer-title">Product</label>
                            </div>
                            <div>
                                <label class="footer-label py-2 pointer" (click)="navigateToOverview()">Overview</label>
                            </div>
                            <div>
                                <label class="footer-label py-2 pointer" (click)="navigateToFeatures()">Features</label>
                            </div>
                            <div>
                                <label class="footer-label py-2 pointer" (click)="navigateToPlans()">Pricing</label>
                            </div>
                            <div>
                                <label class="footer-label py-2 pointer" (click)="navigateToCaseStudy()">Case studies</label>
                            </div>
                        </div>
           
            
            <div class="col-xs-2 col-md-2 col-sm-2 mt-5 mb-2">
                <div>
                    <label class="footer-title">Resources</label>
                </div>
                <div>
                    <label class="footer-label py-2 pointer" (click)="navigateToNewsLetter()">Newsletter</label>
                </div>
                <div>
                    <label class="footer-label py-2 pointer" (click)="navigateToBlog()">Blog</label>
                </div>
            </div>
            <div class="col-xs-3 col-md-3 col-sm-3 mt-5 mb-2  float-end" style="text-align:start">
                <div>
                    <label class="footer-title pointer">Help</label>
                </div>
                <div>
                    <label class="footer-label py-2 pointer" (click)="navigateToContactUs()">Contact us</label>
                </div>
                <div >
                            <!-- <img src="assets/icons/vertical-line.png" class="vertical-img "/> -->
                            <label class="footer-label py-2 pointer" (click)="navigateToPolicy()">Privacy</label>
                        
                </div>
            </div>
        </div>
        <!-- <div class="float-end mt-0 px-5 pt-0">
                <label class="privacy-label px-3">©2022 Azureautomation.ai</label>
                <img src="assets/icons/vertical-line.png" class="vertical-img"/>
                <label class="privacy-label pointer ps-3" (click)="navigateToPolicy()">Privacy</label>
        </div>
        <div class="pb-5">
            <label style="color:transparent">Texting </label>
        </div> -->
        </div>
</div>
