<div class="col-12 template-bg my-5 py-5">
    <div class="container col-8">
        <div class="container my-2">
            <div class="white-bg">
                <div class="py-4 px-5">
                    <img src="assets/images/Logo-label.png" class="logo" alt="logo" />
                    <hr />
                    <p class="date-label my-2">7 October 2021</p>
                    <h5 class="user-label">Hey Amgad</h5>
                    <label class="message-details py-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non
                        turpis
                        cursus odio arcu neque. Mauris gravida suscipit cursus sagittis elit ut duis.</label>

                </div>
            </div>
        </div>

        <div class="container my-5 ">
            <img src="assets/images/mail-template-image-one.png" class="main-img" />
            <div class="white-bg">
                <div class="p-5">
                    <h4 class="content-title py-1 ">Unlocking the potential and
                        removing barriers of adopting AI for business</h4>
                    <label class="content py-1 ">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non turpis
                        cursus odio
                        arcu neque. Mauris
                        gravida suscipit cursus sagittis elit ut duis.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non turpis cursus odio arcu neque.
                        Mauris
                        gravida
                        suscipit cursus sagittis eli...</label>
                    <a class="readmore py-3 pointer ">Read more</a>
                </div>
            </div>
        </div>

        <div class="container my-5">
            <img src="assets/images/mail-template-image-two.png" class="main-img" />
            <div class="white-bg">
                <div class="p-5">
                    <h4 class="content-title py-1">Unlocking the potential and
                        removing barriers of adopting AI for business</h4>
                    <label class="content py-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non turpis
                        cursus odio
                        arcu neque. Mauris
                        gravida suscipit cursus sagittis elit ut duis.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non turpis cursus odio arcu neque.
                        Mauris
                        gravida
                        suscipit cursus sagittis eli...</label>
                    <a class="readmore py-3 pointer">Read more</a>
                </div>
            </div>
        </div>


        <div class="container white-bg my-5 py-3">
            <div class="d-flex justify-content-center my-3">
                <img src="assets/icons/logo-icon.png" class="header-logo pointer" />
            </div>
            <div class="d-flex justify-content-center">
                <label class="privacy-label px-3">©2022 .ai</label>
            </div>
            <div class="d-flex my-4 justify-content-center">
                <img src="assets/icons/tweet-icon.svg" class="social-icon pointer pt-1 mt-1"
                    (click)="navigateToTwitter()" />
                <img src="assets/icons/linkedin-icon.svg" class="social-icon pointer mx-2 pt-1 mt-1"
                    (click)="navigateToLinkedin()" />
            </div>

        </div>


    </div>
</div>