import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router ,NavigationEnd} from '@angular/router';
declare var $: any;
import { Title } from '@angular/platform-browser';
import { TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  data: any;
  httpClient: any;
  isOpen = false;

  videoUrl = "assets/videos/a3s-why.mp4"
  @ViewChild('seeHow') private seeHow: ElementRef;
  @ViewChild('a3sWhy') private a3sWhy: ElementRef;
  
  title = 'AI-powered Azure Automation';
  constructor(public viewContainerRef: ViewContainerRef,private modalService: NgbModal, private router: Router,private titleService:Title) { }

  ngOnInit() {
    
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  }
  openSeeHowVideoComponent() {

  }

  redirectToMarketPlace() {
    window.open("https://azuremarketplace.microsoft.com/en-us/marketplace/apps/dynaptsolutionsinc1598289478779.a3s?tab=Overview", "_blank");
  }
  redirectToAppSource() {
    window.open("https://appsource.microsoft.com/en-cy/product/web-apps/dynaptsolutionsinc1598289478779.a3s?tab=Overview", "_blank");
  }
  navigateToFeature() {
    this.router.navigate(['features']);
  }
  navigateToCaseStudy() {
    this.router.navigate(['case-study']);
  }
  navigateToCaseStudyOne() {
    this.router.navigate(['caseStudies'], { queryParams: { caseStudy: "one" } });
  }
  navigateToCaseStudyTwo() {
    this.router.navigate(['caseStudies'], { queryParams: { caseStudy: "two" } });
  }

  stop(){
    this.seeHow.nativeElement.pause()
  }

  stopA3s(){
    this.a3sWhy.nativeElement.pause()
  }

  hideModal() {
    //$("#exampleModal").modal("hide");
    $("#exampleModal").on('hidden.bs.exampleModal', function (e) {
      $("#exampleModal iframe").attr("src", $("#exampleModal iframe").attr("src"));
  });
  }

  modalHide() {
    //$("#a3sModal").modal("hide");
    $("#a3sModal").on('hidden.bs.modal', function (e) {
      $("#a3sModal iframe").attr("src", $("#a3sModal iframe").attr("src"));
  });
  }
  
  // openModal(){
  //   $('#a3sModal').on('show.bs.modal', function (e) {
  //     //var idVideo = $(e.relatedTarget).data('id');
  //     $('#a3sModal .modal-body').html('<iframe width="100%" height="400px" src="assets/video/a3s-why.mp4" frameborder="0" allowfullscreen></iframe>');
  //   });
  // }

  openFirstModal(){
    $('#exampleModal').on('show.bs.modal', function (e) {
      //var idVideo = $(e.relatedTarget).data('id');
      $('#exampleModal .modal-body').html('<iframe width="100%" height="400px" src="assets/video/a3s-why.mp4" frameborder="0" allowfullscreen></iframe>');
    });
  }

  navigateToContactUs(){
    this.router.navigate(['contact-us'],{queryParams: {contact:"tryNow"}});
  }


  close(){
    console.log("open")
  }

  closeA3sModal() {
    this.modalService.dismissAll();
    //this.product.nativeElement.pause()
    //this.closeFilter();
  }

  openModal(template: TemplateRef<any>) {
   // this.selectedData = list
    this.modalService.open(template, { windowClass: 'custom-popup'});
  }

  closeHowModal(){
    this.modalService.dismissAll();
  }

}
