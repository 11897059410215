import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router,NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-individual-case-study',
  templateUrl: './individual-case-study.component.html',
  styleUrls: ['./individual-case-study.component.scss']
})
export class IndividualCaseStudyComponent implements OnInit {
  case:any;
  secondCaseStudy:boolean = false;
  firstCaseStudy:boolean = false;
  
 title = 'AI-powered Azure Automation Customer Case Studies';
 
  constructor(  private activatedRoute: ActivatedRoute,private router: Router,private titleService:Title) { }

  ngOnInit(){
    
      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0)
    });
    const flag = JSON.stringify(this.activatedRoute.snapshot.queryParams);
    const flagObj = JSON.parse(flag);
    this.case =flagObj.caseStudy;

    if(this.case == "one"){
       this.firstCaseStudy = true;
       this.secondCaseStudy = false;
    }
    else{
      this.secondCaseStudy = true;
      this.firstCaseStudy = false;
    }
  }

  backToSecondCaseStudy(){
    this.router.navigate(['case-study']);
  }



}
