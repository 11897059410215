import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {Router,NavigationEnd} from "@angular/router";
import { Title } from '@angular/platform-browser';
import { TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  title = 'AI-powered Azure Automation Overview';
  @ViewChild('overview') private overview: ElementRef;
  constructor(private router: Router,private titleService:Title,private modalService: NgbModal) { }

  ngOnInit() {
   
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  }

  stop(){
    this.overview.nativeElement.pause()
  }

  hideModal() {
    //$("#overviewModal").modal("hide");
    $("#overviewModal").on('hidden.bs.modal', function (e) {
      $("#overviewModal iframe").attr("src", $("#overviewModal iframe").attr("src"));
  });
  }

  
  closeModal() {
    this.modalService.dismissAll();
    //this.product.nativeElement.pause()
    //this.closeFilter();
  }

  openModal(template: TemplateRef<any>) {
   // this.selectedData = list
    this.modalService.open(template, { windowClass: 'custom-popup'});
  }
 

}
