<div id="main">
        <div class="res">
            <div class="content">
                <h3>Sample1 h3 tag</h3>
            </div>
            <button class="cmd">Generate PDF</button>
        </div>
        <div class="res">
            <div class="content">
                <h3>Sample2 h3 tag</h3>
            </div>
            <button class="cmd">Generate PDF</button>
        </div>
        <div class="res">
            <div class="content">
                <h3>Sample3 h3 tag</h3>
            </div>
            <button class="cmd">Generate PDF</button>
        </div>
    </div>
    <div class="content-template">
      <h3>Sample2 h3 tag</h3>
      <p>Sample pararaph €€€€€</p>
    </div>
    
    <!--Add External Libraries - JQuery and jspdf 
    check out url - https://scotch.io/@nagasaiaytha/generate-pdf-from-html-using-jquery-and-jspdf
    -->
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.3.5/jspdf.min.js"></script>