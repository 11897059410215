import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CreateUserService } from '../shared/create-user.service';
import {Router,NavigationEnd} from "@angular/router";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactUsForm: FormGroup;
  fullName: any;
  email:any;
  phoneNumber:any;
  companyName:any;
  companyUrl:any;
  message:any;
  image:any;
  tryNowImage:boolean = false;
  contactUsImage:boolean = false;
  title = 'AI-powered Azure Automation Contact';
  checkform:boolean =false;
  constructor(private activatedRoute: ActivatedRoute,private fb: FormBuilder,
    private titleService:Title,private userService: CreateUserService, private router: Router) {

   }


  ngOnInit(): void {
    
    this.initForm();
    const flag = JSON.stringify(this.activatedRoute.snapshot.queryParams);
    const flagObj = JSON.parse(flag);
    this.image =flagObj.contact;

    if(this.image == "tryNow"){
       this.tryNowImage = true;
       this.contactUsImage = false;
    }
    else{
      this.contactUsImage = true;
      this.tryNowImage = false;
    }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  }
  private initForm() {
    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    this.contactUsForm = this.fb.group({
      
      'fullName': new FormControl('', [Validators.required,Validators.pattern('^[a-zA-Z ]*$'), Validators.minLength(2), Validators.maxLength(200)]),
      'email': new FormControl('', [Validators.required,Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      'phoneNumber': new FormControl('', [Validators.required,Validators.pattern('^[0-9]*$'),Validators.maxLength(30)]),
      'companyName': new FormControl('', [Validators.required,Validators.minLength(2), Validators.maxLength(200)]),
      'companyUrl': new FormControl('', [Validators.required,Validators.pattern(reg), Validators.maxLength(200)]),
      'message': new FormControl('', [Validators.required,Validators.minLength(2), Validators.maxLength(5000)]),
    });
  }




  onKey(event:any){

    if(this.fullName != " " && this.email != " " && this.phoneNumber != " " && this.companyName != " " && this.companyUrl != " " && this.message !=" "){
      this.checkform = true;
    }
    else{
      this.checkform = false;
    }
  }


  createContact(basicInfo: any) {

    // if (this.contactUsForm.dirty) {
      if (!this.contactUsForm.valid) {
        console.log("Please fill all the fields")
        this.checkform = false;
      }
      else {
        this.checkform = true;
        const personalData: any = {
          fullName: basicInfo.fullName,
          email: basicInfo.email,
          cellNumber: basicInfo.phoneNumber,
          companyName: basicInfo.companyName,
          companyUrl: basicInfo.companyUrl,
          message: basicInfo.message
        }
        this.userService.createContactDetails(personalData).subscribe(async (result: any) => {
           if (result.message= "success") {
            console.log(result);
            this.router.navigate(['success'],{queryParams: {thanksMsg:"Thank you for reaching out for us ",teamMsg:"Our team will get in touch with you very soon."}})
           }
        }, (error) => {
          if (error.status = '500') {
            console.log(error)
          }
          else {
            console.log(error)
          }
          console.log(error)
        });

      }
    // }
  }



}
