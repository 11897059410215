import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateUserService } from '../shared/create-user.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-un-subscribe',
  templateUrl: './un-subscribe.component.html',
  styleUrls: ['./un-subscribe.component.scss']
})
export class UnSubscribeComponent implements OnInit {
  unSubscribeForm:any;
  email:any;
  
  title = 'AI-powered Azure Automation UnSubscribe';
  constructor(private titleService:Title,private activatedRoute: ActivatedRoute,private userService: CreateUserService, private router: Router) { }

  ngOnInit(): void {
   
    this.initForm();
  }
  private initForm() {
    this.unSubscribeForm = new FormGroup({
      'email': new FormControl('', [Validators.required,Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    });
  }


  unSubscriber(basicInfo: any) {
    if (this.unSubscribeForm.dirty) {
      if (!this.unSubscribeForm.valid) {
        console.log("Please fill all the fields")
      }
      else {
        const personalData: any = {
          email: basicInfo.email,
          status:false
        }
        this.userService.createSubscribersDetails(personalData).subscribe(async (result: any) => {
          if (result.message= "success") {
            this.router.navigate(['success'],{queryParams: {thanksMsg:"You have unsubscribed to A3S newsletter",teamMsg:""}})
          }
        }, (error) => {
          if (error.status = '500') {
            console.log(error)
          }
          else {
            console.log(error)
          }
          console.log(error)
        });

      }
    }
  }

}
