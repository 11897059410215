<section class="container my-5 py-5 d-flex justify-content-center">
        <div class="justify-content-center mt-5">
            <div class="col-sm-12 col-md-12 clo-xs-12 justify-content-center text-center  my-5">
                <img src="assets/images/error-icon.png" class="tick-icon" />
                <h5 class="thank-title pt-2">404</h5>
                <label class="thank-label pt-2">That page can’t be found.</label><br/>
                <button class="back-home-btn justify-content-center text-center pt-2 mt-3 pointer" (click)="navigateToHomePage()"> Back to home</button>
            </div>
        </div>
    </section>
    <hr/>