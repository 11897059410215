<div *ngIf="this.firstCaseStudy == true && this.secondCaseStudy == false">
<section class="col-12 pt-5 mt-5 hero-section-bg">
    <div class="pt-3">
        <div class="d-flex justify-content-start text">
            <div class="col-xs-9 col-md-9 col-sm-9 mt-5">
                <div class="ps-5 ms-5">
                    <!-- <img class="w-100 carousel-img" src="assets/images/overview-hero-img.png" alt="hero-image"> -->
                    <div class="text-start">
                        <p class=" plans-subtitle">Enterprise company benefits from ‘Zero Code, 100% Automated’ SAP Data Migration & Analytics with A3S. </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="container text-right">
    <div class="col-xs-12 col-md-12 col-sm-12 my-5 py-5 d-flex justify-content-end " id="plans" style="width:100%">
        <div class="col-xs-4 col-md-4 col-sm-4 ">
            <div>
                <label class="left-panel-title">Automated SAP Data Migration<br/>
                        & Analytics on Azure</label>
                <!-- <img src="assets/images/microsoft-logo-with-label.png" alt="microsoft-logo-with-label" class="logo" /> -->
            </div>
            <div class="pt-4">
                <label class="col-12 website-title">Industry </label>
                <a class="col-12 website-subLabel pointer" >Electronic Design Automation</a>
            </div>
            <div class="pt-4">
                <label class="col-12 website-title">Segment</label>
                <label class="col-12 website-subLabel">Enterprise</label>
            </div>
            <div class="pt-4">
                <label class="col-12 website-title">Geography</label>
                <label class="col-12 website-subLabel">USA</label>
            </div>
        </div>
        <div class="col-xs-8 col-md-8 col-sm-8">
            <div class="col-12 d-flex">
                <div class="col-6 text-start float-start d-flex">
                                <img src="assets/images/back-icon.png" alt="back-icon" (click)="backToSecondCaseStudy()" class="back-icon mt-1 me-2 pointer" />
                                <p class="back-icon-label pointer" (click)="backToSecondCaseStudy()">Back</p>
                                <label class="date-label justify-content-start mx-3">22 Sept 2021</label>
                </div >
                <div class="col-6 text-end">
                        <!-- <label class="download-pdf justify-content-end cmd"><a><img src="assets/images/download-icon.png" (click)="cmd"
                            alt="download-icon" class="download-icon" /></a>Download PDF</label> -->
                </div>
            </div>
            <div class="pt-5">
                <label class="main-content-title">About the customer</label>
                <p class="main-content pt-2">
                        Our customer is in Electronic Design Automation industry and enables electronic systems and semiconductor companies to create the innovative end products that are transforming the way people live, work and play. Its software, hardware and IP are used by customers to deliver products to market faster. The company helps their customers develop differentiated products—from chips to boards to intelligent systems—in mobile, consumer, cloud, data center, automotive, aerospace, IoT, industrial and other market segments. 
                        <br /><br /></p>
                   
                    <label class="main-content-title">Problem</label>
                    <p class="main-content pt-2">
                            The customer’s finance team was looking to streamline their Account Receivables (AR) since they were not efficiently managed, ‭were thus underperforming and directly impacting overall financial performance of the company. The team was looking to address ‭‬the ‬problem ‭of ‭‬reducing ‭outstanding ‭receivables ‬through improvements in the collections ‭strategy. Specifically, they wanted to see if Machine Learning could ‬be used to build ‭models ‭for ‭‬predicting the payment ‭‬outcomes ‭‬of ‭‬newly created ‭invoices, thus enabling customized collection actions tailored ‬for each ‬invoice or customer. ‭Business outcome & success criteria sought was to build models that can ‭predict with ‭high accuracy if an ‭‬invoice will ‭‬be paid on time ‬or not ‭and can provide estimates of the ‭ ‬magnitude of ‭the delay. <br/><br/>‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬

                            In terms of technology, the customer had a large on-premises SAP landscape and the invoices were stored in SAP ECC system with BW NetWeaver version 7.5. The customer’s IT team who was supporting the finance team in this initiative had been trying to get the invoices out of SAP ECC using Azure Data Factory and thereon transfer them to Azure Synapse for downstream analytics. They were able to extract and transfer the full data load but were not able to transfer the delta loads to update the changes to the full data that was already stored in Azure Synapse. The major challenge faced by the team with ADF in managing the ‘Change Data Capture’ was becoming a showstopper for the whole project. <br/><br/>
                </p>
                <label class="main-content-title">Solution provided by A3S</label>
                <p class="main-content pt-2">
                        A3S has a custom built, patent pending automated data migration technology that can ingest data from wide variety of data sources such as SQL, Oracle, SaaS applications like Salesforce, Dynamics 365, AWS S3 and appliances such as Netezza, Teradata etc. and transfer to Azure Synapse. Automated data migration from source system to Azure Synapse has been built with ‘Zero Code 100% Automated’ approach and with just few clicks data transfer is initiated and completed without any manual effort to write code, create & manage pipelines etc.<br/>

                        Once the data is in Azure Synapse, A3S provides fully automated analytics as a service that has prebuilt, pretrained machine learning models that can tackle vast number of business scenarios and use cases. Customers just need to provide details of the data to be analyzed and in just few clicks the ML driven insights are available for use.
                        
                </p>
            </div>
            <div class="col-12  mt-5">
                <div class="p-4">
                    <img src="assets/images/case-studies.png" class="main-content-image" />
                    <!-- <label class="menu-quotes">“Lorem ipsum dolor sit amet, consecte adipiscing elit. Sed non volutpat
                        risus semper donec varius morbi pulvinar. “</label>
                    <label class="col-12 website-title mt-3">Mike Williams</label>
                    <p class="col-12 website-subLabel ">Microsoft Cloud Manager</p> -->
                </div>
            </div>

            <div class="main-content pt-5">
                <label class="main-content-title">Benefits to the customer</label>
                <p class="main-content pt-3">
                        The customer used the automated data migration to transfer the full load from SAP ECC to Azure Synapse. And subsequently, they scheduled the delta load transfers based on their requirements. The delta load transfer can be triggered manually as well so they have complete flexibility. <br/><br/>

                        Once the full and delta load issue was resolved and all the invoices were available in Azure Synapse, the customer thereon used A3S’ automated data governance functionality to put in place all their organizational data access, management and security policies. <br/><br/>
                        
                        As the final step, the customer used A3S’ fully automated analytics as a service and leveraged pre trained predictive ML models to conduct their AR analysis. They successfully trained and tested multiple variants to create a set of models that they would be using to analyze all their invoices on regular basis to streamline and efficiently manage the account receivables.<br/><br/>
                        
                        Customer was able to achieve this end-to-end scenario from data ingestion to analytics within a week and thereon were able to spend time on tackling the business problem. With this initial experience with A3S, customer realized that Azure platform can be leveraged much more effectively with A3S to solve their business problems and they need not become Azure gurus to get the best that the platform has to offer. It is just the start and there are already many other initiatives being planned by other business teams to use A3S and leverage Azure for all their analytics requirements. 
                </p>
            </div>

        </div>
    </div>
</section>
</div>


<div  *ngIf="this.secondCaseStudy == true && this.firstCaseStudy == false">
        <section class="col-12 pt-5 mt-5 hero-section-bg">
            <div class="pt-3">
                <div class="d-flex justify-content-start text">
                    <div class="col-xs-11 col-md-11 col-sm-11 mt-4">
                        <div class="ps-5 ms-5">
                            <!-- <img class="w-100 carousel-img" src="assets/images/overview-hero-img.png" alt="hero-image"> -->
                            <div class="text-start">
                                <p class=" plans-subtitle">Midmarket company benefits from ‘Zero Code, 
                                        100% Automated’ infrastructure Assessment, Migration 
                                        and Windows Virtual Desktop deployment on Azure. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="container text-right">
            <div class="col-xs-12 col-md-12 col-sm-12 my-5 py-5 d-flex justify-content-end " id="plans" style="width:100%">
                <div class="col-xs-4 col-md-4 col-sm-4 ">
                    <div>
                        <label class="left-panel-title">Automated Assessment, Migration<br/>
                                & Windows Virtual Desktop <br/>
                                setup on Azure</label>
                        <!-- <img src="assets/images/microsoft-logo-with-label.png" alt="microsoft-logo-with-label" class="logo" /> -->
                    </div>
                    <div class="pt-4">
                        <label class="col-12 website-title">Industry </label>
                        <a class="col-12 website-subLabel pointer" >Renewables & Environment</a>
                    </div>
                    <div class="pt-4">
                        <label class="col-12 website-title">Segment</label>
                        <label class="col-12 website-subLabel">Mid-Market</label>
                    </div>
                    <div class="pt-4">
                        <label class="col-12 website-title">Geography</label>
                        <label class="col-12 website-subLabel">USA, Mexico & China</label>
                    </div>
                </div>
                <div class="col-xs-8 col-md-8 col-sm-8">
                    <div class="col-12 d-flex">
                        <div class="col-6 text-start float-start d-flex">
                            <img src="assets/images/back-icon.png" alt="back-icon" (click)="backToSecondCaseStudy()" class="back-icon mt-1 me-2 pointer" />
                            <p class="back-icon-label pointer" (click)="backToSecondCaseStudy()">Back</p>
                            <label class="date-label justify-content-start mx-3">7 July 2021</label>
                        </div >
                        <div class="col-6 text-end">
                           
                                <!-- <label class="download-pdf justify-content-end cmd"><a><img src="assets/images/download-icon.png" (click)="cmd"
                                    alt="download-icon" class="download-icon" /></a>Download PDF</label>  -->
                        </div>
                    </div>
                    <div class="pt-3">
                        <label class="main-content-title">About the customer</label>
                        <p class="main-content pt-2">
                                Our client is a company specializing in environmental and agronomic solutions for the treatment of exhaust air, wastewater, hazardous waste and organic waste streams. They provide highly reliable and cost-effective engineered solutions for environmental compliance, resource reuse, GHG emissions reduction, and renewable energy production. Based in the United States and with offices in Mexico and China, they are a business partner with a strong engineering heritage and a passion for solving problems for their customers and providing sustainable technology solutions to solve complex environmental challenges across multiple industries.

                                <br /><br /></p>
                           
                            <label class="main-content-title">Problem</label>
                            <p class="main-content pt-2">
                                    The company has always been laser focused on business aspects of renewables and environment, thus most of their investments go towards building & sustaining the teams that work on various initiatives. They had barebones IT team with a mix of few full-time employees and contractors hired on as needed basis. Their complete infrastructure was on-premises and being maintained & managed by inhouse team that also doubled up as technology support team for the business teams spread across 3 countries and multiple time zones. Needless to say, IT team was stretched thin and barely able to keep their heads above the water leaving zero time for thinking ahead and adopting innovative technologies.  <br/><br/>
   
                                    With all that, the company bought a business unit aligned with their domain of renewables and environment from another company in midst of Covid 19 in Fall 2020. As part of this acquisition, they had 50+ members joining their existing team and all these new team members needed to be setup with the IT infrastructure from laptops, network printers, file share, access to specialized software applications that they used for accomplishing their work at earliest else they won’t be working and be productive. The challenge was compounded as due to Covid people were working remotely and even ordering a laptop and getting it in reasonable amount of time was a challenge, leave aside making sure that the laptop had all required software & applications and was fully secure from cyber and data security perspective. <br/> <br/>                                
                                      
                        </p>
                        <label class="main-content-title">Solution provided by A3S</label>
                        <p class="main-content pt-2">
                                AI-powered Azure Automation as a Service or A3S not only solved company’s each and every problem but modernized their entire IT infrastructure and converted them to 100% cloud based from being completely on-premises in under 3 weeks with ‘Zero Code, 100% Automated’ approach coupled with AI-powered insights and actions! <br/><br/>
                           
                                
                                Key highlights and deliverables from A3S to the client:<br/></p>
                                <ul>
                                        <li class="main-content  mb-0">Automated assessment of client’s on-premises hardware and software assets.</li>
                                        <li class="main-content  mb-0">With A3S’ patent pending drag & drop architecture design tool a comprehensive best practices led Hub & Spoke Virtual Data Center (VDC) built and deployed with a single click on Azure.</li>
                                        <li class="main-content  mb-0">Automated migration of their complex ERP to Azure in one of the Spokes.</li>
                                        <li class="main-content mb-0">Automated migration of all their LOB applications with needed refactoring, along with other assets like network file system to Azure File Share etc.</li>
                                        <li class="main-content  mb-0">Automated deployment of Windows Virtual Desktops (WVDs) on Azure for all their employees with the needed software applications preinstalled.</li>
                                        <li class="main-content  mb-0">Once fully setup on Azure, the client was provided with fully automated infrastructure and application health monitoring and remediation functionality.</li>
                                        <li class="main-content  mb-0">Automated continual cost monitoring and optimization functionality.</li>
                                        <li class="main-content  mb-0">Complete Azure deployment was covered with comprehensive and fully automated governance, security and compliance functionality.</li>
                                        
                                </ul>
                               
                        
                        <p class="main-content pt-2">
                                The client was then transitioned to 24x7 managed services support team to help them mitigate any issues that they might run into across any part of their Azure deployment. Most importantly, their employees got round the clock support for any issues they might face, especially with Windows Virtual Desktop.
                        </p>
                    </div>
                   
        
                    <div class="main-content pt-3">
                        <label class="main-content-title">Benefits to the customer</label>
                        <p class="main-content pt-3">
                                The client continues to leverage A3S as they build out newer initiatives and projects on Azure, leveraging its automated deployment, automated data migration, governance and analytics as a service capabilities. <br/><br/>

                                They now have a reliable 24x7 on-demand support available for their business teams that are spread across 3 countries and multiple time zones.   <br/>
                                Their cost structure has completely changed from CapEx to OpEx and not only that, but the cost has also reduced drastically as they no long need to hire contractors to staff the existing IT team. The current team in turn is now has ample time to focus on technological innovation in the company’s domain of renewables and environment. <br/><br/>
                                
                                A3S offered the client an easy to use solution that leverages AI & Automation to provide a simple way to use, expand & innovate with Azure. Their teams have only scratched the surface of what is possible with A3S and Azure and if this initial experience and the success of A3S to date are any indication, they’re looking at a bright future, indeed.
                                
                        </p>
                    </div>
        
                </div>
            </div>
        </section>
        </div>
       
        
<app-get-started></app-get-started>