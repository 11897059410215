<section class="pt-5">
        <div class="pt-3">
            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active" id="carouselExampleCaptions">
                        <img class="w-100 carousel-img" src="assets/images/overview-hero-img.png" alt="hero-image">
                        <div class="carousel-caption">
                            <h1 class="overview-top-img-header pt-4">Features</h1>
                            <p class="overview-top-img-sub-title">Discover all the features of A3S built with ‘Zero Code,
                                100% Automated’ approach coupled with AI-powered insights and actions that make your Azure
                                usage experience super easy & a truly delightful one.</p>
                            <div class="pt-3 row">
                                <!-- <button class="overview-button me-2 justify-content-center d-flex" data-toggle="featuremodal" data-target="#featuremodal"><span><img
                                            class="play-button mx-2" src="assets/icons/play-icon.svg" /></span>Product
                                    overview</button> -->
                                    <button type="button" class="overview-button me-2 justify-content-center d-flex video-btn" (click)="openModal(openCal)">
                                            <span><img class=" mx-2" src="assets/icons/play-icon.svg"
                                                style="width: 15px;height:15px" /></span>Product
                                                overview
                                      </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="mt-5 pt-5">
        <div class="container col-xs-12 col-md-12 col-sm-12 pt-3 d-flex justify-content-center">
            <div class="col-xs-10 col-md-10 col-sm-10 justify-content-center d-flex">
                <h3 class="features-content">
                    Comprehensive Azure Adoption & Lifecycle Management
                </h3>
            </div>
        </div>
    </section>
    <section class="">
        <div class="container col-xs-12 col-md-12 col-sm-12 ">
            <div class="col-xs-12 col-md-12 col-sm-12 my-5 py-3">
                <div class="row row-cols-1 row-cols-md-3 g-4">
                    <div class="col-xs-3 col-md-3 col-sm-3">
                        <div class="card h-100 p-3">
                            <div class="col-xs-12 col-md-12 col-sm-12 d-flex">
                                <div class="col-9 justify-content-start">
                                    <img src="assets/images/digital-transformation.png" class=""
                                        style="width: 100px;height: 90px;margin-top: 0 px;" alt="deticated-support">
                                </div>
                                <div class="col-3 justify-content-end">
                                    <img src="assets/images/features-group.png" class=""
                                        style="width:40px;height:41px;margin-top:18px;" alt="features-grc">
                                </div>
                            </div>
                            <div class="card-body ps-0">
                                <h6 class="card-title mt-1">Digital<br />Transformation</h6>
                                <div class="white-background my-2">
                                    <label class="py-2">Apps & Infrastructure<br /></label>
                                </div>
                                <div class="white-background my-2">
                                    <label class="py-2">Data & AI<br /></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-3 col-md-3 col-sm-3">
                        <div class="card h-100  p-3">
                            <div class="d-flex">
                                <div class="col-9 justify-content-start">
                                    <img src="assets/images/monitoring.png" class=""
                                        style="width: 100px;height: 90px;margin-top: 0 px;" alt="deticated-support">
                                </div>
                                <div class="col-3 justify-content-end">
                                    <img src="assets/images/features-group.png" class=""
                                        style="width:40px;height:41px;margin-top:18px;" alt="features-grc">
                                </div>
                            </div>
                            <div class="card-body ps-0">
                                <h6 class="card-title mt-1">Monitoring & Remediation</h6>
                                <div class="white-background my-2">
                                    <label>Infrastructure
                                        Health Monitoring</label>
                                </div>
                                <div class="white-background my-2">
                                    <label>Application
                                        Health Monitoring</label>
                                </div>
                                <div class="white-background my-2">
                                    <label>Cost Monitoring
                                        & Optimization</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-3 col-md-3 col-sm-3">
                        <div class="card h-100  p-3">
                            <div class="col-12 d-flex">
                                <div class="col-9 justify-content-start">
                                    <img src="assets/images/features-grc.png" class=""
                                        style="width: 100px;height: 90px;margin-top: 0 px;" alt="deticated-support">
                                </div>
                                <div class="col-3 justify-content-end">
                                    <img src="assets/images/features-group.png" class=""
                                        style="width:40px;height: 41px;margin-top:18px;" alt="features-grc">
                                </div>
                            </div>
                            <div class="card-body ps-0">
                                <h6 class="card-title mt-1">Governance, Risk & Compliance (GRC)</h6>
                                <div class="white-background my-2">
                                    <label class="py-2">Governance</label>
                                </div>
                                <div class="white-background my-2">
                                    <label class="py-2">Security</label>
                                </div>
                                <div class="white-background my-2">
                                    <label class="py-2">Compliance</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-3 col-md-3 col-sm-3">
                        <div class="card h-100  p-3">
                            <div class="col-12 d-flex">
                                <div class="col-9 justify-content-start">
                                    <img src="assets/images/deticated-support.png" class=""
                                        style="width: 100px;height: 90px;margin-top: 0 px;" alt="deticated-support">
                                </div>
                                <div class="col-3 justify-content-end">
                                    <img src="assets/images/features-group.png" class=""
                                        style="width:40px;height: 41px;margin-top:18px;" alt="features-grc">
                                </div>
                            </div>
    
                            <div class="card-body ps-0">
                                <h6 class="card-title mt-1">Dedicated<br />Support</h6>
                                <div class="white-background my-2">
                                    <label class="py-2 align-items-center">A3S Support</label>
                                </div>
                                <div class="white-background my-2">
                                    <label class="py-2 align-items-center">Azure Support</label>
                                </div>
                                <div class="white-background my-2">
                                    <label class="py-2 align-items-center">SAP Support</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="accordion-menu-header-section mb-4">
        <div class="container col-xs-12 col-md-12 col-sm-12 flexDirection" id="feature-section">
            <div class="col-xs-6 col-md-6 col-sm-6 px-5 py-5 mt-3 mb-1">
                <div>
                    <h3 class="main-section-title">Get the most out of
                        Azure with A3S. </h3>
                </div>
                <div>
                    <label class="main-section-title-content">Check out the product features that our team has built with a
                        <a class="color:#27BEFF" style="text-decoration: none">‘Zero Code, 100% Automated’</a> approach coupled with AI-powered insights
                        and actions to simplify and elevate your make your Azure usage experience! </label>
                </div>
            </div>
            <div class="col-xs-6 col-md-6 col-sm-6">
                <img src="assets/images/feature-main-section.png"/>
            </div>
        </div>
    </section>
    <section class="container  mt-5 pt-5">
        <mat-accordion class="mb-3 mt-5 pt-3">
            <mat-expansion-panel (opened)="panelOpenAppInfra = true" (closed)="panelOpenAppInfra = false" hideToggle="true">
                <mat-expansion-panel-header class="disable_ripple pb-4" >
                    <mat-panel-title class="">
                        <label class="accordion-header px-3">
                            Apps & Infrastructure <br />
                            <p class="accordion-description col-10">
                                Enables modernization of your application and infrastructure
                                landscape with automated assessment, migration, architecture blueprint deployment and
                                management
                                of resources & applications.
                            </p>
                        </label>
                    </mat-panel-title>
                    <md-icon *ngIf="!panelOpenAppInfra"><img src="assets/icons/accordion-arrow-top-icon.png"
                        class="plus-icon" /></md-icon>
                    <md-icon *ngIf="panelOpenAppInfra"><img src="assets/icons/accordion-down-arrow-icon.png"
                        class="plus-icon" /></md-icon>
                </mat-expansion-panel-header>
                <div class="col-12 d-flex pt-3">
                    <div class="col-6 px-3">
                        <div class="col-10">
                            <label class="feature-accordion-inner-section-content mt-5">On-premises Assessment</label>
                            
                        </div>
                        <hr class="col-10" style="color:gray">
                        <div class="col-10">
                            <label class="feature-accordion-inner-section-content">Migration to Azure</label>
                        </div>
                        <hr class="col-10" style="color:gray">
                        <div class="col-10">
                            <label class="feature-accordion-inner-section-content">Architecture blueprint
                            </label>
                        </div>
                        <hr class="col-10" style="color:gray">
                        <div class="col-10">
                            <label class="feature-accordion-inner-section-content">Manage Infrastructure
                            </label>
                            <ul class="ps-3 py-2 col-10">
                                <li class="feature-accordion-inner-section-tag">Drag & drop functionality to create
                                    architecture diagrams.</li>
                                <li class="feature-accordion-inner-section-tag">Workflow to finalize the architecture and
                                    thereon deployment on Azure.</li>
                            </ul>
                        </div>
                        <hr class="col-10" style="color:gray">
                        <div class="col-10">
                            <label class="feature-accordion-inner-section-content">Business Continuity </label>
                        </div>
                        <!-- <hr style="color:gray"> -->
                    </div>
                    <div class="col-6">
                        <img src="assets/images/feature-app-infra.png" alt="panel-img" class="accordion-panel-img" />
                        <img src="assets/icons/settings-icon.png" style="width:64px;height:64px;margin-top: -104px;" class="mt-n5">
    
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <hr class="mt-4" style="color:gray">
        <mat-accordion class="mb-3">
            <mat-expansion-panel (opened)="panelOpenDataAI = true" (closed)="panelOpenDataAI = false" hideToggle="true">
                <mat-expansion-panel-header class="disable_ripple pb-4" >
                    <mat-panel-title>
                        <label class="accordion-header p-3 pt-5">
                            Data &AI <br />
                            <p class="accordion-description col-10">
                                Enables modernization of your data estates with automated data migration and analytics while
                                also providing comprehensive automated data governance and DataOps capabilities.
                            </p>
                        </label>
                    </mat-panel-title>
                    <md-icon *ngIf="!panelOpenDataAI"><img src="assets/icons/accordion-arrow-top-icon.png"
                        class="plus-icon" /></md-icon>
                    <md-icon *ngIf="panelOpenDataAI"><img src="assets/icons/accordion-down-arrow-icon.png"
                        class="plus-icon" /></md-icon>
                </mat-expansion-panel-header>
                <div class="col-12 d-flex">
                        <div class="col-6 px-3 pt-3">
                            <div class="col-6">
                                <label class="feature-accordion-inner-section-content mt-3">Migrate data from various 
                                        source data systems to Azure.</label>
                                
                            </div>
                            <hr class="col-10" style="color:gray">
                            <div class="col-6">
                                <label class="feature-accordion-inner-section-content">Comprehensive data 
                                        governance and security.</label>
                            </div>
                            <hr class="col-10" style="color:gray">
                            <div class="col-6">
                                <label class="feature-accordion-inner-section-content">Data analytics providing
                                        analytics as a service.
                                </label>
                            </div>
                            <hr class="col-10" style="color:gray">
                            <!-- <div>
                                <label class="feature-accordion-inner-section-content">Manage Infrastructure
                                </label>
                                <ul>
                                    <li class="feature-accordion-inner-section-tag">Drag & drop functionality to create
                                        architecture diagrams.</li>
                                    <li class="feature-accordion-inner-section-tag">Workflow to finalize the architecture and
                                        thereon deployment on Azure.</li>
                                </ul>
                            </div>
                            <hr > -->
                            <div class="col-6">
                                <label class="feature-accordion-inner-section-content">DataOps.</label>
                            </div>
                            <!-- <hr style="color:gray"> -->
                        </div>
                        <div class="col-6">
                            <img src="assets/images/feature-data-ai.png" alt="panel-img" class="accordion-panel-img" />
                        </div>
                    </div>
            </mat-expansion-panel>
        </mat-accordion>
        <hr style="color:gray">
        <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenHealthMonitoring = true" (closed)="panelOpenHealthMonitoring = false"hideToggle="true">
                <mat-expansion-panel-header class="disable_ripple pb-4" >
                    <mat-panel-title>
                        <label class="accordion-header p-3 pt-5">
                            Infrastructure Health Monitoring<br />
                            <p class="accordion-description col-10">
                                Enables automated monitoring of all the deployed resources and provides remediation
                                capabilities to ensure healthy state of the resources.
                            </p>
                        </label>
                    </mat-panel-title>
                    <md-icon *ngIf="!panelOpenHealthMonitoring"><img src="assets/icons/accordion-arrow-top-icon.png"
                        class="plus-icon" /></md-icon>
                    <md-icon *ngIf="panelOpenHealthMonitoring"><img src="assets/icons/accordion-down-arrow-icon.png"
                        class="plus-icon" /></md-icon>
                </mat-expansion-panel-header>
                <div class="col-12 d-flex">
                        <div class="col-6 px-3 pt-3">
                            <div class="col-10">
                                <label class="feature-accordion-inner-section-content mt-3">Monitor infrastructure health by project, subscription, resource groups, and resources.</label>
                                
                            </div>
                            <hr class="col-10" style="color:gray">
                            <div class="col-10">
                                <label class="feature-accordion-inner-section-content">Apply filters to drill down to resource level and 
                                        analyze health metrics of a specific resource.</label>
                            </div>
                            <hr class="col-10" style="color:gray">
                            <div class="col-10">
                                <label class="feature-accordion-inner-section-content"><b>Insights:&nbsp;&nbsp;</b>provides prebuilt queries to retrieve the relevant data of a resource for auditing and
                                        understanding the health pattern.
                                </label>
                            </div>
                            <hr class="col-10" style="color:gray">
                            <!-- <div>
                                <label class="feature-accordion-inner-section-content">Manage Infrastructure
                                </label>
                                <ul>
                                    <li class="feature-accordion-inner-section-tag">Drag & drop functionality to create
                                        architecture diagrams.</li>
                                    <li class="feature-accordion-inner-section-tag">Workflow to finalize the architecture and
                                        thereon deployment on Azure.</li>
                                </ul>
                            </div>
                            <hr > -->
                            <div>
                                <label class="col-10 feature-accordion-inner-section-content"><b>Alerts:&nbsp;&nbsp;</b> enables creation and management of 
                                        alerts for various metrics of Azure resources.</label>
                            </div>
                            <!-- <hr style="color:gray"> -->
                        </div>
                        <div class="col-6">
                            <img src="assets/images/feature-health-monitoring.png" alt="panel-img" class="accordion-panel-img" />
                        </div>
                    </div>
            </mat-expansion-panel>
        </mat-accordion>
        <hr style="color:gray">
        <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenAppHealth = true" (closed)="panelOpenAppHealth = false"hideToggle="true">
                <mat-expansion-panel-header class="disable_ripple pb-4" >
                    <mat-panel-title>
                        <label class="accordion-header p-3 pt-5">
                            Application Health Monitoring<br />
                            <p class="col-10 accordion-description">
                                Enables automated monitoring of all the deployed applications and provides remediation
                                capabilities to ensure healthy state of the applications.
                            </p>
                        </label>
                    </mat-panel-title>
                    <md-icon *ngIf="!panelOpenAppHealth"><img src="assets/icons/accordion-arrow-top-icon.png"
                        class="plus-icon" /></md-icon>
                    <md-icon *ngIf="panelOpenAppHealth"><img src="assets/icons/accordion-down-arrow-icon.png"
                        class="plus-icon" /></md-icon>
                </mat-expansion-panel-header>
                <div class="col-12 d-flex">
                        <div class="col-6 px-3 pt-3">
                            <div class="col-10">
                                <label class="feature-accordion-inner-section-content mt-3">Consolidated health report of applications by project. </label>
                                
                            </div>
                            <hr style="color:gray">
                            <div class="col-10">
                                <label class="feature-accordion-inner-section-content">Use filters to drill down to check & analyze health related metrics of an application.</label>
                            </div>
                            <hr style="color:gray">
                            <div class="col-10">
                                <label class="feature-accordion-inner-section-content">Region based usage & count of users.
                                </label>
                            </div>
                            <hr style="color:gray">
                            <!-- <div>
                                <label class="feature-accordion-inner-section-content">Manage Infrastructure
                                </label>
                                <ul>
                                    <li class="feature-accordion-inner-section-tag">Drag & drop functionality to create
                                        architecture diagrams.</li>
                                    <li class="feature-accordion-inner-section-tag">Workflow to finalize the architecture and
                                        thereon deployment on Azure.</li>
                                </ul>
                            </div>
                            <hr > -->
                            <div class="col-10">
                                <label class="feature-accordion-inner-section-content">Mechanism to keep you alerted when anything goes wrong.</label>
                            </div>
                            <!-- <hr style="color:gray"> -->
                        </div>
                        <div class="col-6">
                            <img src="assets/images/feature-app-health-monioring.png" alt="panel-img" class="accordion-panel-img" />
                        </div>
                    </div>
            </mat-expansion-panel>
        </mat-accordion>
        <hr style="color:gray">
        <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenCostOptimization = true" (closed)="panelOpenCostOptimization = false"hideToggle="true">
                <mat-expansion-panel-header class="disable_ripple pb-4" >
                    <mat-panel-title>
                        <label class="accordion-header p-3 pt-5">
                            Cost Monitoring & Optimization<br />
                            <p class="col-10 accordion-description">
                                Enables real-time monitoring of the usage of resources and provides recommendations to
                                optimize cost by analyzing usage and other relevant data.
                            </p>
                        </label>
                    </mat-panel-title>
                    <md-icon *ngIf="!panelOpenCostOptimization"><img src="assets/icons/accordion-arrow-top-icon.png"
                        class="plus-icon" /></md-icon>
                    <md-icon *ngIf="panelOpenCostOptimization"><img src="assets/icons/accordion-down-arrow-icon.png"
                        class="plus-icon" /></md-icon>
                </mat-expansion-panel-header>
                <div class="col-12 d-flex">
                        <div class="col-6 px-3 pt-3">
                            <div class="col-10">
                                <label class="feature-accordion-inner-section-content mt-3">Track current cost incurred by resources and forecast expenses.</label>
                                
                            </div>
                            <hr class="col-10" style="color:gray">
                            <div class="col-10">
                                <label class="feature-accordion-inner-section-content">View expenditure by scope such as Project, Subscription, etc.</label>
                            </div>
                            <hr class="col-10" style="color:gray">
                            <div class="col-10">
                                <label class="feature-accordion-inner-section-content">Create budgets and get alerted when thresholds are crossed.
                                </label>
                            </div>
                            <hr class="col-10" style="color:gray">
                            <!-- <div>
                                <label class="feature-accordion-inner-section-content">Manage Infrastructure
                                </label>
                                <ul>
                                    <li class="feature-accordion-inner-section-tag">Drag & drop functionality to create
                                        architecture diagrams.</li>
                                    <li class="feature-accordion-inner-section-tag">Workflow to finalize the architecture and
                                        thereon deployment on Azure.</li>
                                </ul>
                            </div>
                            <hr > -->
                            <div class="col-10">
                                <label class="feature-accordion-inner-section-content pe-3">Recommendations to optimize and reduce the cost. </label>
                            </div>
                            <hr class="col-10" style="color:gray">
                            <div class="col-10">
                                    <label class="feature-accordion-inner-section-content">Departmental chargeback functionality.</label>
                            </div>
                            
                        </div>
                        <div class="col-6">
                            <img src="assets/images/feature-cost-monitoring.png" alt="panel-img" class="accordion-panel-img" />
                        </div>
                    </div>
            </mat-expansion-panel>
        </mat-accordion>
        <hr style="color:gray">
        <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenGoverence = true" (closed)="panelOpenGoverence = false"
           hideToggle="true">
                <mat-expansion-panel-header class="disable_ripple pb-4">
                    <mat-panel-title>
                        <label class="accordion-header p-3 pt-5">
                            Governance<br />
                            <p class="accordion-description col-10">
                                Enables and ensures provisioning, implementation & enforcement of enterprise IT policies,
                                Identity & Access Management and Auditing & Logging.
                            </p>
                        </label>
                    </mat-panel-title>
                    <md-icon *ngIf="!panelOpenGoverence"><img src="assets/icons/accordion-arrow-top-icon.png"
                        class="plus-icon" /></md-icon>
                    <md-icon *ngIf="panelOpenGoverence"><img src="assets/icons/accordion-down-arrow-icon.png"
                        class="plus-icon" /></md-icon>
                </mat-expansion-panel-header>
                <div class="col-12 d-flex">
                        <div class="col-6 px-3 pt-3">
                            <div class="col-10">
                                <label class="feature-accordion-inner-section-content mt-3">Enterprise policies to ensure resources are governed per applicable organizational policies.</label>
                                
                            </div>
                            <hr style="color:gray" class="col-10">
                            <div class="col-10">
                                <label class="feature-accordion-inner-section-content">Identity and access management provides access control framework to manage the users and groups.</label>
                            </div>
                            <hr style="color:gray" class="col-10">
                            <div class="col-10">
                                <label class="feature-accordion-inner-section-content">Auditing & logging captures all user activity & actions performed in A3S for review and auditing as needed.
                                </label>
                            </div>
                        </div>
                        <div class="col-6">
                            <img src="assets/images/feature-goverence.png" alt="panel-img" class="accordion-panel-img" />
                        </div>
                    </div>
            </mat-expansion-panel>
        </mat-accordion>
        <hr style="color:gray">
        <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenSecurity = true" (closed)="panelOpenSecurity = false" hideToggle="true">
                <mat-expansion-panel-header class="disable_ripple pb-4">
                    <mat-panel-title>
                        <label class="accordion-header p-3 pt-5">
                            Security<br />
                            <p class="accordion-description col-10" >
                                Enables and ensures that all deployed Azure resources are secure from network breaches and
                                unauthorized access.
                            </p>
                        </label>
                    </mat-panel-title>
                    <md-icon *ngIf="!panelOpenSecurity"><img src="assets/icons/accordion-arrow-top-icon.png"
                        class="plus-icon" /></md-icon>
                    <md-icon *ngIf="panelOpenSecurity"><img src="assets/icons/accordion-down-arrow-icon.png"
                        class="plus-icon" /></md-icon>
                </mat-expansion-panel-header>
                <div class="col-12 d-flex">
                        <div class="col-6 px-3 pt-3">
                            <div class="col-10">
                                <label class="feature-accordion-inner-section-content mt-3">Calculates ‘Secure score’ based on analysis of multiple dimensions of all deployed and active projects. </label>
                                
                            </div>
                            <hr style="color:gray" class="col-10">
                            <div class="col-10">
                                <label class="feature-accordion-inner-section-content">Endpoints and identity protection to capture malicious activities to secure various resources.</label>
                            </div>
                            <hr style="color:gray" class="col-10">
                            <div class="col-10">
                                <label class="feature-accordion-inner-section-content">Network traffic analysis for malicious activities.
                                </label>
                            </div>
                            <hr style="color:gray" class="col-10">
                            <div class="col-10">
                                <label class="feature-accordion-inner-section-content">Recommendations to strengthen the security posture.</label>
                            </div>
                        </div>
                        <div class="col-6">
                            <img src="assets/images/feature-security.png" alt="panel-img" class="accordion-panel-img" />
                        </div>
                    </div>
            </mat-expansion-panel>
        </mat-accordion>
        <hr style="color:gray">
        <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenCompliance = true" (closed)="panelOpenCompliance = false"hideToggle="true">
                <mat-expansion-panel-header class="disable_ripple pb-4" >
                    <mat-panel-title>
                        <label class="accordion-header p-3 pt-5">
                            Compliance<br />
                            <p class="accordion-description">
                                Enables and ensures that all deployed resources are following all applicable regulatory
                                compliances.
                            </p>
                        </label>
                    </mat-panel-title>
                    <md-icon *ngIf="!panelOpenCompliance"><img src="assets/icons/accordion-arrow-top-icon.png"
                        class="plus-icon" /></md-icon>
                    <md-icon *ngIf="panelOpenCompliance"><img src="assets/icons/accordion-down-arrow-icon.png"
                        class="plus-icon" /></md-icon>
                </mat-expansion-panel-header>
                <div class="col-12 d-flex">
                        <div class="col-6 px-3 pt-3">
                            <div class="col-10">
                                <label class="feature-accordion-inner-section-content mt-3">Ensures resources are compliant with the required or applicable regulatory compliances.</label>
                            </div>
                            <hr style="color:gray" class="col-10">
                            <div>
                                <label class="feature-accordion-inner-section-content">Compliance policy management.</label>
                            </div>
                            <hr style="color:gray" class="col-10">
                            <div class="col-10">
                                <label class="feature-accordion-inner-section-content">Add/remove regulatory compliance standards.
                                </label>
                            </div>
                        </div>
                        <div class="col-6">
                            <img src="assets/images/feature-compliance.png" alt="panel-img" class="accordion-panel-img" />
                        </div>
                    </div>
            </mat-expansion-panel>
        </mat-accordion>
        <hr style="color:gray">
        <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenSupport = true" (closed)="panelOpenSupport = false" hideToggle="true">
                <mat-expansion-panel-header class="disable_ripple pb-4">
                    <mat-panel-title>
                        <label class="accordion-header p-3 pt-2">
                            Dedicated 24x7 Relentless Support<br />
                            <!-- <p class="accordion-description col-10" style="color:transparent">
                                Enables and ensures that all deployed resources are following all applicable regulatory
                                compliances.
                            </p> -->
                        </label>
                    </mat-panel-title>
                    <md-icon *ngIf="!panelOpenSupport" style="margin-top:-45px"><img src="assets/icons/accordion-arrow-top-icon.png"
                        class="plus-icon" /></md-icon>
                    <md-icon *ngIf="panelOpenSupport" style="margin-top:-45px"><img src="assets/icons/accordion-down-arrow-icon.png"
                        class="plus-icon" /></md-icon>
                </mat-expansion-panel-header>
                <div class="col-12 d-flex pb-5 mb-2">
                        <div class="col-6 px-3">
                            <div class="col-10 d-flex">
                                <img src="assets/images/a3s.png" class="accordion-logo-image pe-2" />
                                <label class="feature-accordion-inner-section-content ps-2"><b>A3S Support</b><br/>
                                    Get product related support from trained A3S product engineers, log tickets and chat for instant help. </label>
                            </div>
                            <hr style="color:gray"/>
                            <div class="col-10 d-flex">
                                <img src="assets/images/feature-Azure.png" class="accordion-logo-image pe-2" />
                                <label class="feature-accordion-inner-section-content ps-2 pt-1"><b>Azure Support</b><br/>
                                    Azure certified resources for building custom applications and deployments on Azure with A3S.</label>
                            </div>
                            <hr style="color:gray"/>
                            <div class="col-10 d-flex">
                                <img src="assets/images/sap.png" class="accordion-logo-image pe-2" />
                                <label class="feature-accordion-inner-section-content ps-2 pt-1"><b>SAP Support</b><br/>
                                    SAP certified resources for migration of SAP systems to Azure and ongoing Basis & Functional support with A3S.
                                </label>
                            </div>
                        </div>
                        <div class="col-6 justify-content-end pe-5" style="text-align:end">
                            <img src="assets/images/feature-support.png" alt="panel-img" class="support-image" />
                        </div>
                    </div>
            </mat-expansion-panel>
        </mat-accordion>
    </section>
    
    <!-- <div class="modal fade" id="featuremodal" tabindex="-1" role="dialog" aria-labelledby="featuremodalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <button type="button" class="close" click="hideModal()" data-dismiss="featuremodal" aria-label="Close" style="width:31px;
                  height: 17px;
                  background: transparent;
                  border: none;
                  justify-content: flex-end;
                  float: right;
                  align-content: end;
                  align-items: flex-end;
                  align-self: flex-end;
                  text-align: end;">
                      <span aria-hidden="true">&times;</span>
                    </button>
                <div class="modal-body">
                    <div class="ratio ratio-16x9">
                        <iframe src="assets/video/Product-overview.mp4" title="YouTube video"  type="mp4" allowfullscreen></iframe>
                      </div>
                </div>
              </div>
            </div>
          </div> -->
    
    
          <!-- <div class="modal fade" data-backdrop="static" 
          data-keyboard="false" id="featuremodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-body">
                     <button type="button" (click)="stop()" class="close" data-dismiss="modal" aria-label="Close" style="width:31px;
                     height: 17px;
                     background: transparent;
                     border: none;
                     justify-content: flex-end;
                     float: right;
                     align-content: end;
                     align-items: flex-end;
                     align-self: flex-end;
                     text-align: end;">
                        <span aria-hidden="true">&times;</span>
                      </button>        
              <div class="">
                    <video width="100%" height="100%" controls #product><source src="https://dynaptresource.blob.core.windows.net/videos/Product-overview.mp4" type="video/mp4" autoplay="false"></video>
              </div>
                      
                      
                    </div>
              
                  </div>
                </div>
              </div>  -->
    
    
              <ng-template #openCal  >
                    <div class="medium-popup-wrapper" >
                        <div class="modal-top">
                            <!-- <div class="popup-title">Choose time range</div> -->
                            <button type="button" (click)="closeModal()" style="border: 1px solid white;background: #FFF;margin-left: 97%;" class="close" aria-label="Close" >
                                <span aria-hidden="true" style="width:15px;height: 15px;">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body" >
                                <div class="">
                                        <video width="100%" height="100%" controls #product autoplay><source src="https://dynaptresource.blob.core.windows.net/videos/Product-overview.mp4" type="video/mp4" ></video>
                                  </div>
                        </div>
                    </div>
                </ng-template>
    
    
    <app-get-started class="mt-3"></app-get-started>