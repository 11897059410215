<section class="container">
        <div class="container col-12 py-5 my-5">
            <div class="col-xs-12 col-md-12 col-sm-12 pt-2">
                <form class="col-xs-12 col-md-12 col-sm-12 pt-5 row g-1" [formGroup]="unSubscribeForm" #myform="ngForm">
                    <div class="col-xs-6 col-md-6 col-sm-6 mt-1 form-box-shadow pe-3">
                        <div class="p-5 ">
                            <div class="col-xs-9 col-md-9 col-sm-9">
                                <label class="newletter-title">Unsubscribe to A3S newsletter</label>
                            </div>
                            <div class="col-xs-12 col-md-12 col-sm-12 mt-5">
                                <div class="d-flex">
                                    <label class="contact-us-label">Email</label>
                                    <img src="assets/images/required-icon.png" class="required-icon" />
                                </div>
                                <input class="form-control text-label" formControlName="email" [(ngModel)]="email"
                                    [ngClass]="(unSubscribeForm.get('email').hasError('required') && myform.Submitted)?'form-control-invalid': 'form-control' "
                                    type="textbox" placeholder="" required />
                                <div *ngIf="unSubscribeForm.get('email').hasError('required') && myform.Submitted"
                                    class="small text-danger mt-1">Required</div>
                                <div *ngIf="unSubscribeForm.get('email').hasError('pattern')" class="small text-danger mt-1">
                                    Invalid email format.</div>
                                <div *ngIf="unSubscribeForm.get('email').hasError('maxlength')"
                                    class="small text-danger mt-1">
                                    Eamil cannot exceed 200 characters</div>
                                <div *ngIf="unSubscribeForm.get('email').hasError('minlength')"
                                    class="small text-danger mt-1">
                                    Eamil should contain 2 characters</div>
                            </div>
                            <div class="col-xs-12 col-md-12 col-sm-12 mt-5">
                                <button class="col-xs-12 col-md-12 col-sm-12 newletter-button pointer" (click)="unSubscriber(unSubscribeForm.value)">Unsubscribe</button>
                                <div hidden class="small text-danger mt-1">Something went wrong, please try again later
                                </div>
                            </div>
    
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
    <hr>