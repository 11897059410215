import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
  title = 'AI-powered Azure Automation Blog';
  constructor(private router: Router,private titleService:Title) { 

    let linkedinBtn = document.querySelector(".linkedin-btn");


  }

  ngOnInit(){
   
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });

  this.toLinkedin();
  }


  toLinkedin(){
    let linkedinBtn = document.querySelector(".linkedin-btn");
    let twitterBtn = document.querySelector(".twitter-btn");
    let postUrl = encodeURI(document.location.href);
    let postTitle =encodeURI("A3s Azure");
    linkedinBtn.setAttribute("href",`https://www.linkedin.com/shareArticle?url=${postUrl}&title=${postTitle}`);
    twitterBtn.setAttribute("href",`https://twitter.com/share?url=${postUrl}&text=${postTitle}`);

  }

/****
 * https://twitter.com/share?url=[post-url]&text=[post-title]
 * https://www.linkedin.com/shareArticle?url=[post-url]&title=[post-title]


 * 
 * 
 * 
 * 
 *  */



  navigateToLinkedin(){
    window.location.href = 'https://www.linkedin.com/company/azure-automation-as-service/';
  }
  navigateToTwitter(){
    window.location.href = ' https://twitter.com/ai_a3s';
  }

  backToBlogs(){
    this.router.navigate(['blog']);
  }

}
