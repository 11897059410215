import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from '../app/components/home-page/home-page.component';
import { OverviewComponent } from './components/overview/overview.component';
import { FeaturesComponent } from './components/features/features.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { SuccessScreenComponent } from './components/success-screen/success-screen.component';
import { ErrorComponent } from './components/error/error.component';
import { CaseStudiesComponent } from './components/case-studies/case-studies.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { TryNowComponent } from './components/try-now/try-now.component';
import { NewLetterComponent } from './components/new-letter/new-letter.component';
import { UnSubscribeComponent } from './components/un-subscribe/un-subscribe.component';
import { IndividualCaseStudyComponent } from './components/individual-case-study/individual-case-study.component';
import { BlogComponent } from './components/blog/blog.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { EmailTemplateComponent } from './components/email-template/email-template.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TestComponent } from './components/test/test.component';


const routes: Routes = [
        {path: '',component:HomePageComponent,pathMatch: 'full',data: {title: 'AI-powered Azure Automation as a Service',description: 'We offer Zero Code, 100% Automated AI-powered Azure Automation as a Service to modernize Azure Cloud Lifecycle Management with AI & Automation.', keywords: ' AI-powered Azure Automation as a Service, AI-powered Azure Automation as a Service,Reimagining & Modernizing Cloud Lifecycle Management with AI & Automation,Reimagining and modernizing cloud lifescycle management,Turnkey Solution,Simplify & Elevate your Azure usage experience with A3S'}},
        {path: 'overview',component:OverviewComponent,data: {title: 'Zero Code, 100% Automated AI-powered Azure Automation as a Service – Solution Overview.',description: 'Get an overview of various capabilities of AI-powered Azure Automation as a Service. Simplify & Elevate your Azure usage experience with A3S, get started with our free trial or request a demo. ', keywords: 'AI-powered Azure Automation as a Service Overview,Zero Code, 100% Automated – Simplify & Elevate your Azure usage experience with A3S,AI-powered Azure Automation Case Studies,Azure Automation Case Studies,Discover how A3S can help you leverage Azure services,Apps & Infra Modernization,Bring Your Own Architecture,Assessment & Datacenter Migration,Business Continuity,Comprehensive DevOps,Infrastructure Management,Data Estate Modernization,Data Estate Governance,Analytics as a Service,Comprehensive DataOps,Monitor Apps & Infra,Governance, Risk & Compliance' }},
        {path: 'features',component:FeaturesComponent,data: {title: 'Comprehensive Azure Adoption & Lifecycle Management',description: 'Discover all the A3S features built with ‘Zero Code, 100% Automated’ approach that make your Azure usage experience super easy & a truly delightful one. ',keywords: 'AI-powered Azure Automation as a Service Product Features,Zero Code, 100% Automated – Comprehensive Azure Adoption & Lifecycle Management,Comprehensive Azure Adoption & Lifecycle Management,Get the most out of Azure with A3S.,Check out the product features that our team has built with a ‘Zero Code, 100% Automated’ approach,Apps & Infra Modernization,Infrastructure Health Monitoring,Data &AI,Apps & Infrastructure,Governance,Security,Compliance'}},
        {path: 'contact-us',component:ContactUsComponent,data: {title: ' AI-powered Azure Automation Contact',description:'',keywords:''}},
        {path: 'success',component:SuccessScreenComponent,data: {title: ' AI-powered Azure Automation Thanks',description:'',keywords:''}},
        {path: 'error',component:ErrorComponent,data: {title: ' AI-powered Azure Automation Error',description:'',keywords:''}},
        
        {path: 'case-study',component:CaseStudiesComponent,data: {title: 'AI-powered Azure Automation as a Service Case Studies.', description: 'AI-powered Azure Automation as a Service Case Studies. Discover how A3S can help you leverage Azure services with Automation & AI to make your usage experience super easy & a truly delightful one',keywords: ' AI-powered Azure Automation as a Service Case Studies,Zero Code, 100% Automated – Discover how A3S can help you leverage Azure services and m make your usage experience super easy & a truly delightful one,AI-powered Azure Automation Case Studies,Automated SAP Data Migration & Analytics on Azure,Fully automated Assessment, Migration & WVD setup on Azure'}},
        {path: 'plans',component:PricingComponent,data: {title: 'AI-powered Azure Automation as a Service Pricing | Flexible Pricing Plans ',description:'Check out AI-powered Azure Automation as a Service Pricing Details. We offer Flexible Pricing Plans to fit all your needs. Get started with our free trial or request a demo. ',keywords:''}},
        {path: 'try-now',component:TryNowComponent,data: {title: ' Getting started with Azure or already using it? Check out this amazing AI-powered Azure Automation as a Service Blog to get the latest information and tips to maximize your Azure usage with Automation & AI. ',description:'Have any queries? Contact AI-powered Azure Automation as a Service using the contact form and our team will get in touch with you. Get a Free Trial or demo and get first had experience with A3S Now! ',keywords:''}},
        {path: 'news-letter',component:NewLetterComponent,data: {title: ' Subscribe to AI-powered Azure Automation as a Service Newsletter | Azure Automation & AI ',description:'Subscribe to AI-powered Azure Automation as a Service newsletter to get the latest news and updates about the product. Please fill out the subscription form to subscribe to our newsletter. ',keywords:''}},
        {path: 'unSubscribe',component:UnSubscribeComponent,data: {title: ' AI-powered Azure Automation Newsletter',description:'',keywords:''}},
        {path: 'unSubscribe',component:UnSubscribeComponent,data: {title: ' AI-powered Azure Automation Newsletter',description:'',keywords:''}},
        {path: 'caseStudies',component:IndividualCaseStudyComponent,data: {title: ' AI-powered Azure Automation Customer Case Studies',description:'',keywords:''}},
        {path: 'blog',component:BlogComponent,data: {title: 'AI-powered Azure Automation as a Service Blog | Azure Automation & AI ',description:'Getting started with Azure or already using it? Check out this amazing AI-powered Azure Automation as a Service Blog to get the latest information and tips to maximize your Azure usage with Automation & AI. ',keywords:''}},
        {path: 'blog-details',component:BlogDetailsComponent,data: {title: 'AI-powered Azure Automation Blog',description:'',keywords:''}},
        {path: 'email-template',component:EmailTemplateComponent},
        {path: 'privacy-policy',component:PrivacyPolicyComponent,data: {title: ' AI-powered Azure Automation Policy',description:'',keywords:''}},
      
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'disabled'})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
