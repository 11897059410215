<section class="pt-5">
    <div class="pt-3">
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active" id="carouselExampleCaptions">
                    <img class="w-100 carousel-img" src="assets/images/overview-hero-img.png" alt="hero-image">
                    <div class="carousel-caption">
                        <h1 class="overview-top-img-header pt-5 mt-2">Case Studies</h1>
                        <h2 class="overview-top-img-sub-title">Discover how A3S can help you leverage Azure services</h2>
                        <div class="pt-3 row">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="container">
    <div class="col-xs-12 col-md-12 col-sm-12 my-5 py-5" id="caseStudies">
            <div class="row row-cols-1 row-cols-md-3 g-4">
                    <div class="col-xs-4 col-md-4 col-sm-4">
                      <div class="card casestudy-card-height" style="background: #253241;">
                        <!-- <img src="assets/images/microsoft-case-studies.png" class="card-img-top case-studies-card" alt="microsoft-img"> -->
                        <div class="card-body px-4 py-4">
                          <!-- <h5 class="card-title">Miscrosoft</h5> -->
                          <p class="case-card-title pointer" (click)="navigateToCaseStudyOne()">Automated SAP Data Migration & Analytics on Azure.</p>
                          <a class="case-study-label pointer" style="color: #FFFFFF;" (click)="navigateToCaseStudyOne()">Full case study<span><img
                                src="assets/images/chevron_down.png" class="chervon-down pointer mx-2" /></span> </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-4 col-md-4 col-sm-4">
                      <div class="card casestudy-card-height" style="background: #253241;">
                        <!-- <div class="d-flex">
                              <img src="assets/images/aws.png" class="card-img-top case-studies-card" alt="azure-img">
                          </div> -->
                        <div class="card-body px-3 py-4">
                          <!-- <h5 class="card-title">Amazon Web Services</h5> -->
                          <p class="case-card-title pointer" (click)="navigateToCaseStudyTwo()">Fully automated Assessment, Migration & WVD setup on Azure</p>
                          <a class="case-study-label pointer" style="color: #FFFFFF;" (click)="navigateToCaseStudyTwo()">Full case study<span><img
                                src="assets/images/chevron_down.png" class="chervon-down pointer mx-2" /></span> </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-4 col-md-4 col-sm-4">
                      <div class="card casestudy-card-height" style="background: #48586B;">
                        <!-- <img src="assets/images/cloudways.png" class="card-img-top case-studies-card" alt="teams-ims"> -->
                        <div class="card-body px-3 py-4">
                          <!-- <h5 class="card-title">Cloudways</h5> -->
                          <p class="case-card-title">Coming soon... <br /><br /><br /></p>
              
                          <a class="case-study-label" style="color: transparent;">Full case study </a>
                        </div>
                      </div>
                    </div>
                  </div>
    </div>
</section>

<app-get-started></app-get-started>