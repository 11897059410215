import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {
  panelOpenAppInfra = false;
  panelOpenDataAI = false;
  panelOpenHealthMonitoring=false;
  panelOpenAppHealth=false;
  panelOpenCostOptimization=false;
  panelOpenGoverence=false;
  panelOpenSecurity=false;
  panelOpenCompliance= false;
  panelOpenSupport=false;
  title = 'AI-powered Azure Automation Product Features';
  @ViewChild('product') private product: ElementRef;
  constructor(private router: Router,private titleService:Title, private modalService: NgbModal) { }
  ngOnInit() {
    
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  }

  hideModal() {
   $("#featuremodal").modal("hide");
    $("#featuremodal").on('hidden.bs.modal', function (e) {
      $("#featuremodal iframe").attr("src", $("#featuremodal iframe").attr("src"));
  });
  }

  stop(){
    this.product.nativeElement.pause()
  }

  // start(){
  //   this.product.nativeElement.play()
  // }

  closeModal() {
    this.modalService.dismissAll();
    //this.product.nativeElement.pause()
    //this.closeFilter();
  }

  openModal(template: TemplateRef<any>) {
   // this.selectedData = list
    this.modalService.open(template, { windowClass: 'custom-popup'});
  }

}
