import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateUserService } from '../shared/create-user.service';
import {NavigationEnd} from "@angular/router";
import { Title } from '@angular/platform-browser';



@Component({
  selector: 'app-new-letter',
  templateUrl: './new-letter.component.html',
  styleUrls: ['./new-letter.component.scss']
})
export class NewLetterComponent implements OnInit {

  newsLetterForm:any;
  fullName: any;
  email:any;
  checkform:boolean = false;
  
 title = 'AI-powered Azure Automation NewsLetter';

  constructor(private titleService:Title,private activatedRoute: ActivatedRoute,private userService: CreateUserService, private router: Router) { }

  ngOnInit(){
    
    this.initForm();
      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0)
    });
  }
  private initForm() {
    this.newsLetterForm = new FormGroup({
      'fullName': new FormControl('', [Validators.pattern('^[a-zA-Z ]*$'), Validators.minLength(2), Validators.maxLength(200)]),
      'email': new FormControl('', [Validators.required,Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    });
  }

  
  onKey(event:any){

    if(this.email != ""){
      this.checkform = true;
    }
    else{
      this.checkform = false;
    }
  }


  createSubscriber(basicInfo: any) {

    if (this.newsLetterForm.dirty) {
      if (!this.newsLetterForm.valid) {
        console.log("Please fill all the fields");
        this.checkform = false;
      }
      else {
        this.checkform = true;
        const personalData: any = {
          fullName: basicInfo.fullName,
          email: basicInfo.email,
          status:true
        }
        this.userService.createSubscribersDetails(personalData).subscribe(async (result: any) => {
           if (result.message= "success") {
            console.log(result);
            this.router.navigate(['success'],{queryParams: {thanksMsg:"You have subscribed to A3S newsletter",teamMsg:""}})
          }
        }, (error) => {
          if (error.status = '500') {
            console.log(error)
          }
          else {
            console.log(error)
          }
          console.log(error)
        });

      }
    }
  }



}
