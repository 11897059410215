<section class="pt-5">
  <div class="pt-3">
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"
          aria-current="true" aria-label="Slide 1" href="#carouselExampleIndicators" role="button"
          data-slide="prev"></button>
        <button type="button" data-bs-target="#carouselExampleCaptionsOne" data-bs-slide-to="1" aria-label="Slide 2"
          href="#carouselExampleIndicators" role="button" data-slide="next"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active" id="carouselExampleCaptions">
          <img class="carousel-img" src="assets/images/group.png" alt="hero-image">
          <div class="carousel-caption">
            <h1 class="carousel-overlay-header-content">Reimagining & Modernizing Cloud Lifecycle Management with <a
                class="red-color">AI & Automation.</a></h1>
            <!-- <p class="carousel-overlay-subheader-content">Modernizing Cloud Lifecycle Management with <a class="red-color">AI & Automation</a></p> -->
            <div class="col-12 pt-5 mt-5 d-flex">
              <button class="carousel-try-button me-3" (click)="navigateToContactUs()">Try it now</button>
              <!-- <button class="carousel-video-button me-2 d-flex"    
              (click)="openFirstModal()"
               ><span><img class="play-button mx-2"
                    src="assets/icons/play-icon.svg" /></span>See How?</button> -->
                    <button type="button" class="carousel-video-button me-2 justify-content-center d-flex video-btn" (click)="openModal(openHowItWorks)">
                        <span><img class="mx-2" src="assets/icons/play-icon.svg"
                            style="width: 15px;height:15px" /></span>See How?
                  </button>
            </div>
          </div>
        </div>
        <div class="carousel-item" id="carouselExampleCaptionsOne">
          <img class="carousel-img" src="assets/images/caruosel.png" alt="hero-image">
          <div class="carousel-caption">
            <h6 class="carousel-overlay-header-content">Zero Code,<br /><a class="red-color">100% Automated. </a></h6>
            <p class="carousel-overlay-subheader-content">AI-powered Azure<br /> Automation as a Service.</p>
            <p class="carousel-overlay-content">Modernize Azure Cloud Lifecycle Management<br /> with AI & Automation
            </p>
            <div class="col-12 pt-5 d-flex">
              <button class="carousel-try-button me-3" (click)="navigateToContactUs()">Try it now</button>
              <!-- <button class="carousel-video-button me-2 d-flex" (click)="openModal()"
              
              ><span><img class="play-button mx-2"
                    src="assets/icons/play-icon.svg" /></span>Why A3S?</button> -->
                    <button type="button" class="carousel-video-button me-2  d-flex video-btn" (click)="openModal(openA3sVideo)">
                        <span><img class="me-2" src="assets/icons/play-icon.svg"
                            style="width: 15px;height:15px" /></span>Why A3S?
                  </button>
            </div>
          </div>
        </div>
      </div>
      <!-- <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a> -->
    </div>
  </div>
</section>
<section class="mt-5 pt-5" id="main-page-first-section">
  <div class="container col-xs-12 col-md-12 col-sm-12 pt-3">
    <div class="row row-cols-1 row-cols-md-3 g-4">
      <div class="col-xs-3 col-md-3 col-sm-3">
        <div class="card h-100 p-3">
          <img src="assets/images/microsoft.png" class="card-img-top card-img" alt="microsoft-img">
          <div class="card-body py-3">
            <h5 class="card-title">Microsoft Gold partner</h5>
            <p class="card-content">Built by a team with 10+ years of deep Azure expertise & experience of lifecycle
              management of complex deployments, ongoing monitoring & management, and support.</p>
          </div>
        </div>
      </div>
      <div class="col-xs-3 col-md-3 col-sm-3">
        <div class="card h-100 p-3">
          <div class="d-flex">
            <img src="assets/images/azure.png" class="card-img-top card-img" alt="azure-img">
            <img src="assets/images/plus-icon.png" class="card-img-top plus-img" alt="plus-img" />
            <img src="assets/images/a3s-logo.png" class="card-img-top card-img" alt="a3s-img" />
          </div>
          <div class="card-body py-3">
            <h5 class="card-title">Turnkey Solution</h5>
            <h2 class="card-content">Microsoft Azure Cloud Adoption Framework based solution with Azure CSP enablement
              makes it a one stop solution that’s available on <a style="text-decoration: underline;color:#6A79C9;cursor: pointer;" href="https://azuremarketplace.microsoft.com/en-us/marketplace/apps/dynaptsolutionsinc1598289478779.a3s?tab=Overview" target="_blank">Azure
                Marketplace</a> and <a style="text-decoration: underline;color:#6A79C9;cursor: pointer;" href="https://appsource.microsoft.com/en-cy/product/web-apps/dynaptsolutionsinc1598289478779.a3s?tab=Overview" target="_blank">AppSource.</a></h2>
          </div>
        </div>
      </div>
      <div class="col-xs-3 col-md-3 col-sm-3">
        <div class="card h-100 p-3">
          <img src="assets/images/teams.png" class="card-img-top card-img" alt="teams-ims">
          <div class="card-body py-3">
            <h5 class="card-title">A3S Bot</h5>
            <p class="card-content">Access to A3S capabilities from Microsoft Teams to create, manage, & monitor
              resources, expenditure, governance, security & compliance, get real-time alerts & instant support.</p>
          </div>
        </div>
      </div>
      <div class="col-xs-3 col-md-3 col-sm-3 ">
        <div class="card h-100 p-3">
          <img src="assets/images/power-bi.png" class="card-img-top card-img" alt="bi-img">
          <div class="card-body py-3">
            <h5 class="card-title">Power BI Integration</h5>
            <p class="card-content">Better together, embed Power BI dashboards in A3S or connect directly to do more
              with automated data migration and analytics as a service capabilities of A3S.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="d-flex">
  <div class="container col-xs-12 col-md-12 col-sm-12 d-flex">
    <div class="col-xs-8 col-md-8 col-sm-8 justify-content-start pt-5 mt-5">
      <h2 class="col-xs-8 col-md-8 col-sm-8 main-content-title pt-3">Simplify & Elevate your Azure usage
        experience with A3S</h2>
      <p class="main-content-sub-title">A fully self-service SaaS solution.</p>
      <p class="main-content">Leverage Azure to its fullest extent without getting into technical complexities of the
        platform. No need to write code or to perform manual deployments, monitoring, management & remediation and
        Governance, Security & Risk (GRC) related activities.<br /><br />

        A3S takes care of all the hard work with a ‘Zero Code, 100% Automated’ approach coupled with AI-powered insights
        and actions to make your Azure usage experience super easy & a truly delightful one. </p>
    </div>
    <div class="col-xs-4 col-md-4 col-sm-4 justify-content-end px-5">
      <img src="assets/images/azure-bg.png" class="azure-bg" alt="azure-bg" />
      <img src="assets/images/azure-img.png" class="azure-img" alt="azure-img" />
    </div>
  </div>
</section>
<section class="feature-section-bg mb-3">
  <div class="col-12 mb-3">
    <div class="col-xs-12 col-md-12 col-sm-12 py-5">
      <div class="container col-xs-9 col-md-9 col-sm-9 justify-content-center d-flex mt-4 mb-5">
        <h2 class="features-header">Microsoft Azure Cloud Adoption Framework + Cutting Edge AI 
            & Automation to deliver</h2>
      </div>
      <div class="container col-xs-12 col-md-12 col-sm-12 d-flex mb-2">
        <div class="col-xs-6 col-md-6 col-sm-6 d-flex">
          <img src="assets/icons/digital-modernization.png" class="features-icon" alt="Digital-Modernization" />
          <div class="px-3">
            <h6 class="features-title">Digital Modernization</h6>
            <p class="features-content">Enables automated assessment, migration, architecture blueprint creation & 
                deployment and modernization of applications, 
                infrastructure & data landscapes and thereon ongoing management & optimization as needed.
            </p>
          </div>
        </div>
        <div class="col-xs-6 col-md-6 col-sm-6 d-flex">
          <img src="assets/icons/monitoring.png" class="features-icon" alt="monitoring" />
          <div class="px-3">
            <h6 class="features-title">Monitoring & Remediation</h6>
            <p class="features-content">Enables automated continuous monitoring of application and infrastructure
              health, proactive & preemptive remedial actions and cost monitoring & optimization.</p>
          </div>
        </div>
      </div>
      <div class="container col-xs-12 col-md-12 col-sm-12 d-flex mb-2">
        <div class="col-xs-6 col-md-6 col-sm-6 d-flex">
          <img src="assets/icons/goverence.png" class="features-icon" alt="goverence" />
          <div class="px-3">
            <h6 class="features-title">Governance, Risk & Compliance</h6>
            <p class="features-content">Enables automated implementation, management and enforcement of IT governance, cyber & network security and regulatory compliance standards & policies with AI-powered insights and remedial actions. 
            </p>
          </div>
        </div>
        <div class="col-xs-6 col-md-6 col-sm-6 d-flex">
          <img src="assets/icons/dedicate-support.png" class="features-icon" alt="dedicate-support" />
          <div class="px-3">
            <h6 class="features-title">Dedicated Support</h6>
            <p class="features-content">24x7 relentless support from certified, highly trained experts for A3S related
              issues and for custom development & deployments you can also get Azure & SAP certified engineers.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center  pt-2 mb-3">
      <button class="feature-button justify-content-center mb-5 pb-3" (click)="navigateToFeature()">See all features</button>
      <!-- <span><img src="assets/icons/arrow-right.svg" alt="arrow-right" class="play-button"/></span> -->
    </div>
  </div>
</section>
<section class="container pt-5 ">
  <div class="col-xs-12 col-md-12 col-sm-12 justify-content-center d-flex pt-3">
    <label class="case-studies py-4">Case Studies</label>
  </div>
  <div class="col-xs-12 col-md-12 col-sm-12" id="caseStudies">
    <div class="row row-cols-1 row-cols-md-3 g-4">
      <div class="col-xs-4 col-md-4 col-sm-4">
        <div class="card" style="background: #253241;">
          <!-- <img src="assets/images/microsoft-case-studies.png" class="card-img-top case-studies-card" alt="microsoft-img"> -->
          <div class="card-body px-4 py-4 casestudy-card-height">
            <!-- <h5 class="card-title">Miscrosoft</h5> -->
            <p class="case-card-title pointer" (click)="navigateToCaseStudyOne()">Automated SAP Data Migration & Analytics on Azure.</p>
            <a class="case-study-label pointer" style="color: #FFFFFF;" (click)="navigateToCaseStudyOne()">Full case study<span><img
                  src="assets/images/chevron_down.png" class="mx-3 chervon-down" /></span> </a>
          </div>
        </div>
      </div>
      <div class="col-xs-4 col-md-4 col-sm-4">
        <div class="card casestudy-card-height" style="background: #253241;">
          <!-- <div class="d-flex">
                <img src="assets/images/aws.png" class="card-img-top case-studies-card" alt="azure-img">
            </div> -->
          <div class="card-body px-3 py-4 casestudy-card-height">
            <!-- <h5 class="card-title">Amazon Web Services</h5> -->
            <p class="case-card-title pointer" (click)="navigateToCaseStudyTwo()">Fully automated Assessment, Migration & WVD setup on Azure</p>
            <a class="case-study-label pointer" style="color: #FFFFFF;" (click)="navigateToCaseStudyTwo()">Full case study<span><img
                  src="assets/images/chevron_down.png" class="mx-3 chervon-down" /></span> </a>
          </div>
        </div>
      </div>
      <div class="col-xs-4 col-md-4 col-sm-4">
        <div class="card casestudy-card-height" style="background: #48586B;">
          <!-- <img src="assets/images/cloudways.png" class="card-img-top case-studies-card" alt="teams-ims"> -->
          <div class="card-body px-3 py-4 casestudy-card-height">
            <!-- <h5 class="card-title">Cloudways</h5> -->
            <p class="case-card-title">Coming soon... <br /><br /><br /></p>

            <!-- <a class="case-study-label" style="color: transparent;">Full case study </a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 d-flex justify-content-center">
    <label class="case-studies-footer-title  mt-5">Start using A3S today to innovate and let's collaborate on<br />
      a case study to share with the world.</label>
  </div>
  <div class="col-xs-12 col-md-12 col-sm-12 d-flex justify-content-center">
    <button class="case-studies-btn my-5 text-center" (click)="navigateToCaseStudy()">See all case studies</button>
  </div>
</section>
<section class="container pt-5 pb-3">
  <div class="d-flex justify-content-center pt-2">
    <label class="core-principles-title">Our Core Principles</label>
  </div>
  <div class="col-xs-12 col-md-12 col-sm-12 my-5 py-3" id="corePrinciples">
    <div class="row row-cols-1 row-cols-md-3 g-4">
      <div class="col-xs-3 col-md-3 col-sm-3">
        <div class="card h-100">
          <img src="assets/images/Automate.png" class="card-img-top card-img" alt="Automate">
          <div class="card-body py-3 ">
            <h5 class="card-title">Automate</h5>
            <p class="card-content" style="color: #5F5F6B;">Reduce manual effort to the minimum, keep automating all
              that is possible across all
              aspects of Azure platform. </p>
          </div>
        </div>
      </div>
      <div class="col-xs-3 col-md-3 col-sm-3">
        <div class="card h-100">
          <div class="d-flex">
            <img src="assets/images/simplify.png" class="card-img-top card-img" alt="simplify">
          </div>
          <div class="card-body py-3">
            <h5 class="card-title">Simplify</h5>
            <p class="card-content" style="color: #5F5F6B;">Cut through the clutter with easy to use monitoring,
              management, optimization &
              reporting capabilities. </p>
          </div>
        </div>
      </div>
      <div class="col-xs-3 col-md-3 col-sm-3">
        <div class="card h-100">
          <img src="assets/images/learn.png" class="card-img-top card-img" alt="learn-img">
          <div class="card-body py-3">
            <h5 class="card-title">Learn</h5>
            <p class="card-content" style="color: #5F5F6B;">Leverage AI to learn from customers’ Azure usage to make the
              solution smarter, ever
              evolving & learning. </p>
          </div>
        </div>
      </div>
      <div class="col-xs-3 col-md-3 col-sm-3">
        <div class="card h-100">
          <img src="assets/images/Support.png" class="card-img-top card-img" alt="Support-img">
          <div class="card-body py-3">
            <h5 class="card-title">Support</h5>
            <p class="card-content">24x7 availability of trained, certified Azure engineers to provide a truly
              delightful Managed Service experience.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-get-started></app-get-started>

<!-- <script>
new ModalVideo('.js-modal-btn');
</script> -->

<!-- <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="originOverlay" [cdkConnectedOverlayOpen]="isOpen">
  <div class="cdk-overlay-container">
    <div id="cdk-overlay-0" class="cdk-overlay-pane" dir="ltr">
      <mat-video title="My Tutorial Title" [autoplay]="true" [preload]="true" [fullscreen]="true" [download]="false"
        color="accent" spinner="spin" poster="image.jpg">
        <source matVideoSource src="https://www.youtube.com/watch?v=8uIe0qY9qG8" type="video/mp4">
      </mat-video>
    </div>
  </div>
</ng-template> -->


  
  <!-- Modal -->

  <!-- <div class="modal fade" id="seeHowModal" data-backdrop="static" 
  data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
           <button type="button" (click)="stop()" class="close" data-dismiss="modal" aria-label="Close" style="width:31px;
           height: 17px;
           background: transparent;
           border: none;
           justify-content: flex-end;
           float: right;
           align-content: end;
           align-items: flex-end;
           align-self: flex-end;
           text-align: end;">
              <span aria-hidden="true">&times;</span>
            </button>        
          <div class="">
                <video id="seeHow" width="100%" height="100%" controls playsinline #seeHow><source src="https://dynaptresource.blob.core.windows.net/videos/see-how.mp4" type="video/mp4" autoplay="true"></video>
          </div>
            
            
          </div>
    
        </div>
      </div>
    </div> 
     -->

  <!-- <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
          <button type="button" class="close" data-dismiss="modal" (click)="hideModal()" aria-label="Close" style="width: 31px;
          height: 17px;
          background: transparent;
          border: none;
          justify-content: flex-end;
          float: right;
          align-content: end;
          align-items: flex-end;
          align-self: flex-end;
          text-align: end;">
              <span aria-hidden="true">&times;</span>
            </button>
        <div class="modal-body">
            <div class="ratio ratio-16x9" id="y-player">
              </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- <script type="text/javascript">
    $('#exampleModalLabel').on('hidden.bs.Modal', function () {
        callPlayer('y-player', 'stopVideo');
    });
</script> -->


<!-- A3s-->
  <!-- <div class="modal fade" id="a3sModal" tabindex="-1" role="dialog" aria-labelledby="a3sModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close" (click)="modalHide()"  data-dismiss="a3sModal" aria-label="Close" style="width:31px;
            height: 17px;
            background: transparent;
            border: none;
            justify-content: flex-end;
            float: right;
            align-content: end;
            align-items: flex-end;
            align-self: flex-end;
            text-align: end;">
                <span aria-hidden="true">&times;</span>
              </button>
          <div class="modal-body">
              <div class="ratio ratio-16x9" id="yt-player">
                </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <script type="text/javascript">
    $('#a3sModal').on('show.bs.modal', function (e) {
  var idVideo = $(e.relatedTarget).data('id');
  $('#a3sModal .modal-body').html('<iframe width="100%" height="400px" src="assets/video/a3s-why.mp4?autoplay=false" frameborder="0" allowfullscreen></iframe>');
});
      $('#a3sModal').on('hidden.bs.a3sModal', function () {
          callPlayer('yt-player', 'stopVideo');
      });
  </script> -->

  <!-- <div class="modal fade" data-bs-backdrop='false' id="a3sModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" 
  data-keyboard="false">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
           <button type="button" (click)="stopA3s()" class="close" data-dismiss="modal" aria-label="Close" style="width:31px;
           height: 17px;
           background: transparent;
           border: none;
           justify-content: flex-end;
           float: right;
           align-content: end;
           align-items: flex-end;
           align-self: flex-end;
           text-align: end;">
              <span aria-hidden="true">&times;</span>
            </button>        
          <div class="">
                <video width="100%" height="100%" controls #a3sWhy><source src="https://dynaptresource.blob.core.windows.net/videos/a3s-why.mp4" type="video/mp4" autoplay="false"></video>
          </div>
            
            
          </div>
    
        </div>
      </div>
    </div>  -->

    <ng-template #openA3sVideo>
        <div class="medium-popup-wrapper" >
            <div class="modal-top">
                <button type="button"  (click)="closeA3sModal()"  style="border: 1px solid white;background: #FFF;margin-left: 97%;" class="close" aria-label="Close" >
                  <span aria-hidden="true" style="width:15px;height: 15px;">&times;</span>
              </button>
            </div>
            <div class="modal-body" style="width:100%;">
                    <div class="">
                            <video width="100%" height="100%" controls #a3sWhy autoplay><source src="https://dynaptresource.blob.core.windows.net/videos/a3s-why.mp4" type="video/mp4" ></video>
                      </div>
            </div>
        </div>
    </ng-template>

    <ng-template #openHowItWorks>
        <div class="medium-popup-wrapper" >
            <div class="modal-top">
              <button type="button" (click)="closeHowModal()"  style="border: 1px solid white;background: #FFF;margin-left: 97%;" class="close" aria-label="Close" >
                  <span aria-hidden="true" style="width:15px;height: 15px;">&times;</span>
              </button>
            </div>
            <div class="modal-body" style="width:100%;">
                    <div class="">
                            <video width="100%" height="100%" controls #seeHow autoplay><source src="https://dynaptresource.blob.core.windows.net/videos/see-how.mp4" type="video/mp4" ></video>
                      </div>
            </div>
        </div>
    </ng-template>

    