import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {Router,NavigationEnd} from "@angular/router";
import { Title } from '@angular/platform-browser';
import { TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;
@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  panelOpenDigitalModernization= true;
  panelOpenDedicatedSupport=false;
  panelOpenGovernance=false;
  panelOpenMonitoring=false;
  @ViewChild('pricing') private pricing: ElementRef;
  title = 'AI-powered Azure Automation Pricing Plans';
  constructor(private router: Router,private titleService:Title,private modalService: NgbModal) { }

  ngOnInit(){
   
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  }

  navigateToContactUs(){
    this.router.navigate(['contact-us'],{queryParams: {contact:"contactUs"}});
  }

  stop(){
    this.pricing.nativeElement.pause()
  }

  hideModal() {
    //$("#pricingmodal").modal("hide");
    $("#pricingmodal").on('hidden.bs.modal', function (e) {
      $("#pricingmodal iframe").attr("src", $("#pricingmodal iframe").attr("src"));
  });
  }

  redirectToMarketPlace() {
    window.open("https://azuremarketplace.microsoft.com/en-us/marketplace/apps/dynaptsolutionsinc1598289478779.a3s?tab=Overview", "_blank");
  }
  redirectToAppSource() {
    window.open("https://appsource.microsoft.com/en-cy/product/web-apps/dynaptsolutionsinc1598289478779.a3s?tab=Overview", "_blank");
  }

  closeModal() {
    this.modalService.dismissAll();
    //this.product.nativeElement.pause()
    //this.closeFilter();
  }

  openModal(template: TemplateRef<any>) {
   // this.selectedData = list
    this.modalService.open(template, { windowClass: 'custom-popup'});
  }

}
