import { Component, OnInit } from '@angular/core';
import {Router,NavigationEnd} from "@angular/router";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  }

  navigateToContactUs(){
    this.router.navigate(['contact-us'],{queryParams: {contact:"contactUs"}});
  }
  navigateToFeatures(){
    this.router.navigate(['features']);
  }
  navigateToOverview(){
    this.router.navigate(['overview']);
  }
  navigateToCaseStudy(){
    this.router.navigate(['case-study']);
  }
  navigateToPlans(){
    this.router.navigate(['plans']);
  }
  navigateToNewsLetter(){
    this.router.navigate(['news-letter']);
  }
  navigateToHomePage(){
    this.router.navigate(['']);
  }
  navigateToBlog(){
    this.router.navigate(['blog']);
  }
  navigateToPolicy(){
    this.router.navigate(['privacy-policy']);
  }
  navigateToLinkedin(){
    window.open("https://www.linkedin.com/company/azure-automation-as-service/", "_blank");
  }
  navigateToTwitter(){
    window.open("https://twitter.com/ai_a3s", "_blank");
  }
  
}
