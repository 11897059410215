import { Content } from '@angular/compiler/src/render3/r3_ast';
import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, map } from "rxjs/operators";



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'AI-powered Azure Automation';
  cookieMessage="We use cookies to provide you with  a better browsing experience and to analyze site traffic. By continuing to use our website, you consent to the use of these cookies.";
  cookieDismiss="Accept";

  constructor (private router: Router, private activatedRoute:ActivatedRoute, private titleService: Title, private metaTagService: Meta) {
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
            let child = this.activatedRoute.firstChild;
            while (child) {
                if (child.firstChild) {
                    child = child.firstChild;
                } else if (child.snapshot.data && child.snapshot.data) {
                    return child.snapshot.data;
                } else {
                    return null;
                }
            }
            return null;
        })
    ).subscribe( (data: any) => {
        if (data) {
            this.titleService.setTitle(data.title);
            this.metaTagService.updateTag({name: 'description', content: data.title + data.description});
            this.metaTagService.addTag({ name: 'keywords', content: data.keywords });
        }
    });
    


    let cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#2C2C38",
          text:"#FFFFFF",
          fontSize:"13px",
          lineHeight:"20px",
        },
        button: {
          background: "#EF3F50",
          text: "#FFFFFF"
        },
        label:{
          fontSize:"13px",
        }
      },
      theme: "classic",
      content: {
        message: this.cookieMessage,
        dismiss: this.cookieDismiss,
     //   link: this.cookieLinkText,
       // href: environment.Frontend + "/dataprivacy" 
      }
    });
  }

  ngOnInit() {
    
  }

 

}
