import { Component, OnInit } from '@angular/core';
import {Router,NavigationEnd} from "@angular/router";
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  title = 'AI-powered Azure Automation Privacy Policy';
  constructor(private router: Router,private titleService:Title,) { }

  ngOnInit(){
   
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  }

}
