import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class CreateUserService {

  constructor(private http: HttpClient) { 

  }

  createContactDetails(userDetails:any) {
    return this.http.post("https://a3s-backend-node-dev.azurewebsites.net/api/create-contact" ,userDetails);
  }
  createSubscribersDetails(subscriberDetails:any) {
    return this.http.post("https://a3s-backend-node-dev.azurewebsites.net/api/subscribeUser" ,subscriberDetails);
  }
  unSubscriberDetails(unsubscribeUser:any) {
    return this.http.post("https://a3s-backend-node-dev.azurewebsites.net/api/unsubscribeUser" ,unsubscribeUser);
  }
  
  
}
