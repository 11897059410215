import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  title = 'AI-powered Azure Automation Error';
  constructor(private router: Router,private titleService:Title) { }

  ngOnInit() {
    
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  }

  navigateToHomePage(){
    this.router.navigate(['']);
  }
 

}
