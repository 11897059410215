import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navigateToOverview(){
    this.router.navigate(['overview']);
  }
  navigateToFeatures(){
    this.router.navigate(['features']);
  }
  navigateToCaseStudy(){
    this.router.navigate(['case-study']);
  }
  navigateToPlans(){
    this.router.navigate(['plans']);
  }
  navigateToHomePage(){
    this.router.navigate(['']);
  }
  login(){
    window.open("https://app.azureautomation.ai/signIn", "_blank");
  }
  navigateToContactUs(){
    this.router.navigate(['contact-us'],{queryParams: {contact:"tryNow"}});
  }
}
