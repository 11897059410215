<div class="col-xs-12 col-md-12 col-sm-12 " class="header-position header-bg" style="width: 100%">
    <div class="col-xs-12 col-md-12 col-sm-12 d-flex px-3 py-2"
        style="flex-wrap: nowrap;;margin: 0px;">
        <div class="col-xs-3 col-md-3 col-sm-3 d-flex float-left">
            <img src="assets/icons/logo-icon.png" class="header-logo pointer" (click)="navigateToHomePage()"/>
            <div class="d-flex">
                <img src="assets/icons/logo-label.svg" class="header-logo-label pointer pt-1 mt-1" (click)="navigateToHomePage()"/>
            </div>
        </div>
        <div class="col-xs-9 col-md-9 col-sm-9 d-flex float-right justify-content-end">
            <div class="col-xs-12 col-md-12 col-sm-12 d-flex float-right justify-content-end">
                <div class="d-flex align-self-center mx-1">
                    <button type="button" class="header-label px-3 pt-1 pointer" (click)="navigateToOverview()"> Overview</button>
                </div>
                <div class="d-flex align-self-center pointer mx-1">
                    <button type="button" class="header-label px-3 pt-1" (click)="navigateToFeatures()"> Features</button>
                </div>
                <div class="d-flex align-self-center pointer mx-1" (click)="navigateToPlans()">
                    <button type="button" class="header-label px-3 pt-1">Pricing</button>
                </div>
                <div class="d-flex align-self-center pointer mx-1">
                    <button type="button" class="header-label px-3" (click)="navigateToCaseStudy()">Case Studies</button>
                </div>
                <div class="d-flex align-self-center">
                    <img src="assets/images/vertical-separator.png" class="vertical-separator mx-4"/>
                </div>
                <div class="d-flex align-self-center pointer mx-1">
                    <a href="https://app.azureautomation.ai/signIn" target="_blank"><button type="button" class="header-label px-3" >Login</button></a>
                </div>
                <div class="py-2 d-flex px-3">
                    <button type="button" class="header-button" (click)="navigateToContactUs()">Try it now</button>
                </div>
            </div>
        </div>
    </div>
</div>