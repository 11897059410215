import { BrowserModule,Title  } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { OverviewComponent } from './components/overview/overview.component';
import { FeaturesComponent } from './components/features/features.component';
import { GetStartedComponent } from './components/get-started/get-started.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { SuccessScreenComponent } from './components/success-screen/success-screen.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorComponent } from './components/error/error.component';
import { CaseStudiesComponent } from './components/case-studies/case-studies.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { TryNowComponent } from './components/try-now/try-now.component';
import { NewLetterComponent } from './components/new-letter/new-letter.component';
import { UnSubscribeComponent } from './components/un-subscribe/un-subscribe.component';
import { IndividualCaseStudyComponent } from './components/individual-case-study/individual-case-study.component';
import { BlogComponent } from './components/blog/blog.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { EmailTemplateComponent } from './components/email-template/email-template.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { MatVideoModule } from 'mat-video';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatDialogModule } from '@angular/material/dialog';
import { TestComponent } from './components/test/test.component';
import { ModalModule } from 'ngx-bootstrap/modal';




@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomePageComponent,
    OverviewComponent,
    FeaturesComponent,
    GetStartedComponent,
    ContactUsComponent,
    SuccessScreenComponent,
    ErrorComponent,
    CaseStudiesComponent,
    PricingComponent,
    TryNowComponent,
    NewLetterComponent,
    UnSubscribeComponent,
    IndividualCaseStudyComponent,
    BlogComponent,
    BlogDetailsComponent,
    EmailTemplateComponent,
    PrivacyPolicyComponent,
    TestComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    ReactiveFormsModule,
    FormsModule,
    MatVideoModule,
    MatDialogModule,
    OverlayModule,
    HttpClientModule,
    ModalModule.forRoot()
  ],
  // providers: [Title,{ provide: 'apiKey', useValue: 'YOUR_API_KEY' },],
  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
