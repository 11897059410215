<section class="pt-5">
    <div class="pt-3">
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active" id="carouselExampleCaptions">
                    <img class="w-100 carousel-img" src="assets/images/overview-hero-img.png" alt="hero-image">
                    <div class="carousel-caption">
                        <h1 class="overview-top-img-header pt-4">Overview</h1>
                        <p class="overview-top-img-sub-title">With a ‘Zero Code, 100% Automated’ approach coupled with AI-powered insights
                                & actions, A3S makes your Azure usage experience super easy & a truly delightful one.</p>
                        <div class="pt-3 row">
                            <!-- <button class="overview-button me-2 justify-content-center d-flex" data-toggle="overviewModal" data-target="#overviewModal"><span><img
                                        class="play-button mx-2" src="assets/icons/play-icon.svg" /></span>Quick
                                preview?</button> -->
                                <button type="button" class="overview-button me-2 justify-content-center d-flex video-btn" (click)="openModal(openVideo)">
                                        <span><img class=" mx-2" src="assets/icons/play-icon.svg"
                                            style="width: 15px;height:15px" /></span>Quick
                                            preview?
                                  </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="mt-5 pt-5">
    <div class="container col-xs-12 col-md-12 col-sm-12 pt-3 d-flex">
        <div class="col-xs-6 col-md-6 col-sm-6 justify-content-start">
            <label class="overview-main-content-label">
                    A3S is a self-service SaaS solution that brings together the power of automation and AI-powered insights & actions to enable you to automate assessment, migration & modernization of your application, infrastructure and data landscapes, to continually monitor, manage & optimize and thereon to implement & enforce your governance, security and compliance policies.<br/><br/>

                    All this without writing a single line of code – just point & click in the A3S portal or use the A3S bot in Microsoft Teams to get the job done! 
                    
            </label>
        </div>
        <div class="col-xs-6 col-md-6 col-sm-6 justify-content-end">
            <img src="assets/images/overview-main-content-img.png" class="main-img" alt="main-img">
        </div>
    </div>
</section>
<section class="">
    <div class="container col-xs-12 col-md-12 col-sm-12 ">
        <div class="col-xs-12 col-md-12 col-sm-12 justify-content-start">
                <div class="row row-cols-1 row-cols-md-3 g-3">
                        <h5 class="col-xs-6 col-md-6 col-sm-6 card-content-title ">Simplify & Elevate your Azure usage
                                experience with A3S</h5>
                    </div>
           
        </div>
        <div class="col-xs-12 col-md-12 col-sm-12 my-4 py-3" id="corePrinciples">
            <div class="row row-cols-1 row-cols-md-3 g-4">
                <div class="col-xs-6 col-md-6 col-sm-6">
                    <div class="card h-100 p-3">
                        <img src="assets/images/apps.png" class="card-img-top card-img " alt="apps">
                        <div class="card-body">
                            <h6 class="card-title mt-1">Apps & Infra Modernization</h6>
                            <p class="card-content">Use the patent pending architecture design tool to simply drag & drop resources from icon palette onto design canvas and create as complex an architecture that your business needs. Thereon, follow simple & intuitive workflow for deploying to Azure with a click of a button.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6 col-md-6 col-sm-6">
                    <div class="card h-100  p-3" >
                        <div class="d-flex">
                            <img src="assets/images/architecture.png" class="card-img-top card-img" alt="architecture">
                        </div>
                        <div class="card-body">
                            <h6 class="card-title mt-1">Bring Your Own Architecture(BYOA)</h6>
                            <p class="card-content">Upload your architecture to the patent pending architecture design tool and thereon follow the simple & intuitive workflow to get an Azure best-practices based architecture that you can review with your team, approve & then deploy to Azure with a click of a button.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6 col-md-6 col-sm-6">
                    <div class="card h-100  p-3">
                        <img src="assets/images/data-center.png" class="card-img-top card-img" alt="data-center">
                        <div class="card-body ">
                            <h6 class="card-title mt-1">Assessment & Datacenter Migration</h6>
                            <p class="card-content">Let A3S conduct an in-depth automated assessment of your on-premises data center assets – both hardware & software with Hyper-V & VMware and thereon Windows & Linux environments to create a roadmap for seamless, automated migration to a secure Virtual Data Center (VDC) in Azure.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6 col-md-6 col-sm-6">
                    <div class="card h-100  p-3">
                        <img src="assets/images/Business-Continuity.png" class="card-img-top card-img"
                            alt="Business-Continuity">
                        <div class="card-body">
                            <h6 class="card-title mt-1">Business Continuity </h6>
                            <p class="card-content">A3S has fully automated capabilities to ensure minimal downtime in case your on-premises assets are down for any reason by standing up the same environment in Azure. It also offers the same protection for Azure deployments by ensuring cross region backup and replication. </p>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6 col-md-6 col-sm-6">
                    <div class="card h-100  p-3">
                        <img src="assets/images/Comprehensive-DevOps.png" class="card-img-top card-img"
                            alt="Comprehensive-DevOps">
                        <div class="card-body">
                            <h6 class="card-title mt-1">Comprehensive DevOps</h6>
                            <p class="card-content">A3S provides a fully automated Azure DevOps based continuous integration and continuous deployment capabilities across various development environments with inherent source control per your organizational needs to provide a consistent and seamless experience to your development teams. </p>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6 col-md-6 col-sm-6">
                    <div class="card h-100  p-3">
                        <img src="assets/images/settings-cog.png" class="card-img-top card-img" alt="settings-cog">
                        <div class="card-body ">
                            <h6 class="card-title mt-1">Infrastructure Management</h6>
                            <p class="card-content">View, analyze, and manage an existing or a new deployment on Azure with A3S. It provides a highly intuitive interface with easy navigation and comprehensive menu of actions that can be used to make the required changes to the resources across all your Azure deployments.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6 col-md-6 col-sm-6">
                    <div class="card h-100  p-3">
                        <img src="assets/images/data-estate-modernization.png" class="card-img-top card-img"
                            alt="data-estate-modernization">
                        <div class="card-body">
                            <h6 class="card-title mt-1">Data Estate Modernization</h6>
                            <p class="card-content">Patent pending framework that caters to all aspects of data estate modernization enables zero pipeline, fully automated migration of data from any source system to Azure. SAP ECC Full and Delta Load functionality is a key highlight among many other supported source systems.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6 col-md-6 col-sm-6">
                    <div class="card h-100  p-3">
                        <img src="assets/images/data-estate-goverence.png" class="card-img-top card-img"
                            alt="data-estate-goverence">
                        <div class="card-body">
                            <h6 class="card-title mt-1">Data Estate Governance</h6>
                            <p class="card-content">A3S enables you to implement and enforce a comprehensive data governance framework that includes Data Discovery & Classification, Business Glossary Tagging, Master Data Management (MDM), Dynamic Data Masking, Row & Column Level Security, Access Control among other capabilities.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6 col-md-6 col-sm-6">
                    <div class="card h-100  p-3">
                        <img src="assets/images/analytics.png" class="card-img-top card-img" alt="analytics">
                        <div class="card-body">
                            <h6 class="card-title mt-1">Analytics as a Service</h6>
                            <p class="card-content">Choose from A3S’ catalog of business focused use cases that are backed by fully trained & tested machine learning models. Simply provide details of your source data systems and with a few clicks you will start receiving actionable insights from your data to grow your business.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6 col-md-6 col-sm-6">
                    <div class="card h-100  p-3">
                        <img src="assets/images/Comprehensive-DataOps.png" class="card-img-top card-img"
                            alt="analytics">
                        <div class="card-body ">
                            <h6 class="card-title mt-1">Comprehensive DataOps</h6>
                            <p class="card-content">Use A3S to enable Azure Synapse Workspace with continuous integration and continuous deployment capabilities and for automated deployments of standardized Workspaces with inherent source control per your organizational needs to provide a consistent and seamless experience to the developers, data engineers & scientists.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6 col-md-6 col-sm-6">
                    <div class="card h-100  p-3">
                        <img src="assets/images/business-report.png" class="card-img-top card-img"
                            alt="business-report">
                        <div class="card-body">
                            <h6 class="card-title mt-1">Monitor Apps & Infra</h6>
                            <p class="card-content">Real-time reporting with intuitive and easy to use dashboards as A3S performs continuous health and cost monitoring of all your applications and infrastructure. Issues are detected in real time and immediate remedial actions are performed to rectify & restore to healthy & cost optimized state. </p>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6 col-md-6 col-sm-6">
                    <div class="card h-100  p-3">
                        <img src="assets/images/grc.png" class="card-img-top card-img" alt="grc">
                        <div class="card-body">
                            <h6 class="card-title mt-1">Governance, Risk & Compliance (GRC)</h6>
                            <p class="card-content">Enables you to implement & manage your organizational IT governance policies, manage identity & access control, perform checks on various aspects of infrastructure to manage the risk levels from network security perspective and to review & enforce various regulatory compliances. </p>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6 col-md-6 col-sm-6">
                    <div class="card h-100  p-3">
                        <img src="assets/images/Support.png" class="card-img-top card-img" alt="Support">
                        <div class="card-body">
                            <h6 class="card-title mt-1">Dedicated Support</h6>
                            <p class="card-content">Relentless 24x7 support from A3S product team for A3S related issues and thereon for all your custom development & deployments you can get Azure certified engineers & for SAP migration to Azure and ogoing monitoring & management you can get SAP certified engineers.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-get-started></app-get-started>

<!--Overview-->

<!-- <div class="modal fade" id="overviewModal" tabindex="-1" role="dialog" aria-labelledby="overviewModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
              <button type="button" class="close"  
              click="hideModal()" data-dismiss="overviewModal" aria-label="Close" style="width: 31px;
              height: 17px;
              background: transparent;
              border: none;
              justify-content: flex-end;
              float: right;
              align-content: end;
              align-items: flex-end;
              align-self: flex-end;
              text-align: end;">
                  <span aria-hidden="true">&times;</span>
                </button>
               
            <div class="modal-body">
                <div class="ratio ratio-16x9">
                        <video width="100%" height="100%" controls><source src="assets/video/overview.mp4" type="video/mp4" autoplay="false"></video>
                    <iframe src="assets/video/overview.mp4" title="YouTube video"  type="mp4" allowfullscreen></iframe> 
                  </div>
            </div>
          </div>
        </div> -->


        <!-- <div class="modal fade" id="overviewModal" data-backdrop="static" 
        data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-body">
                     <button type="button" (click)="stop()" class="close" data-dismiss="modal" aria-label="Close" style="width:31px;
                     height: 17px;
                     background: transparent;
                     border: none;
                     justify-content: flex-end;
                     float: right;
                     align-content: end;
                     align-items: flex-end;
                     align-self: flex-end;
                     text-align: end;">
                        <span aria-hidden="true">&times;</span>
                      </button>        
              <div class="">
                    <video width="100%" height="100%" controls #overview><source src="https://dynaptresource.blob.core.windows.net/videos/overview.mp4" type="video/mp4" autoplay="false"></video>
              </div>
                      
                      
                    </div>
              
                  </div>
                </div>
              </div>  -->

              <ng-template #openVideo>
                    <div class="medium-popup-wrapper" >
                        <div class="modal-top float-right">
                            <button type="button" (click)="closeModal()" style="border: 1px solid white;background: #FFF;margin-left: 97%;" class="close" aria-label="Close" >
                                <span aria-hidden="true" style="width:15px;height: 15px;">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body" style="width:100%;">
                                <div class="">
                                        <video width="100%" height="100%" controls #overview autoplay><source src="https://dynaptresource.blob.core.windows.net/videos/overview.mp4" type="video/mp4" ></video>
                                  </div>
                        </div>
                    </div>
                </ng-template>
    
      